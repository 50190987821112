import React, { useEffect } from 'react';

const Discord: React.FC = () => {

  useEffect(() => {
    window.location.href = `https://discord.gg/CNwwWrxrs8`;
  }, []);

  return <></>;
};

export default Discord;