import { ReactNode, useEffect, useState } from "react"
import { getAPIReceipts, getFilteredReceipts, Receipt, setAPIReceipt } from "../../Domains/Receipts"
import './Receipts.css'
import ReceiptsHeader from "./ReceiptsHeader"
import ReceiptsTable from "./ReceiptsTable"
import ReceiptsStatistics from "./ReceiptsStatistics"
import ReceiptsFooter from "./ReceiptsFooter"
import { PopUp } from "../../Components/PupUp"
import { getReceiptsSettings, setReceiptsSettings } from "../../Domains/Settings"
import ReceiptsSettings from "./Modals/ReceiptsSettings"
import ReceiptsError from "./Modals/ReceiptsError"
import ReceiptsComment from "./Modals/ReceiptsComment"
import { useNavigate } from "react-router-dom"

const Receipts: React.FC = () => {
    const [settings, setSettings] = useState<any>(getReceiptsSettings())

    const [theme, setTheme] = useState<'light' | 'dark'>(settings.theme)
    const [receipts, setReceipts] = useState<Receipt[]>([])

    const [search, setSearch] = useState<string>('')

    const navigate = useNavigate();

    const [statistics, setStatistics] = useState<boolean>(settings.statistics == 1)

    const perPage = settings['page-limit'] ?? 20

    const [page, setPage] = useState<number>(1)
    const [popup, setPopup] = useState<{show: boolean, header?: string, content?: ReactNode}>({show: false})

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getAPIReceipts();
                setReceipts(data)
            } catch (error) {
                setReceipts([])
                console.log(error)
            }
          };
          
        fetchData();
    }, [])

    const saveSettings = (new_settings: {'theme': 'light' | 'dark', 'statistics': boolean, 'page-limit': number}) => {
        setTheme(new_settings.theme)
        setStatistics(new_settings.statistics)
        setReceiptsSettings(new_settings)
        setSettings(new_settings)
        setPopup({show: false})
    }

    const openSettings = () => setPopup({show: true, header: 'Настройка показа чеков', content: <ReceiptsSettings saveSettings={saveSettings}/>})
    const openError = (receipt: Receipt) => {setPopup({show: true, header: `Отмена чека #${receipt.id}`, content: <ReceiptsError receipt={receipt} confirm={(updated) => confirmError(receipt.id, updated)} />})}
    const confirmError = (receipt_id: number,updated: Partial<Receipt>) => {
        setAPIReceipt(receipt_id, updated)
        setPopup({show: false})
    }
    const openComment = (receipt: Receipt) => {setPopup({show: true, header: `Комментарий к чеку #${receipt.id}`, content: <ReceiptsComment receipt={receipt} confirm={(updated) => confirmComment(receipt.id, updated)} />})}
    const confirmComment = (receipt_id: number, updated: Partial<Receipt>) => {
        setAPIReceipt(receipt_id, updated)
        setPopup({show: false})
    }

    const openStatus = (receipt: Receipt) => {
        setAPIReceipt(receipt.id, {status: receipt.status + 1})

        const receiptIndex = receipts.findIndex(r => r.id === receipt.id)
        const receiptToUpdate = receipts[receiptIndex];
        const updatedReceipt = { ...receiptToUpdate, status: receiptToUpdate.status + 1 };
        const updatedReceipts = [...receipts];
        updatedReceipts[receiptIndex] = updatedReceipt;
        setReceipts(updatedReceipts);
    }

    return (
        <>
        <div className={`content ${theme}`}>
            <ReceiptsHeader returnBack={() => navigate('')} statisticOpened={statistics} statisticAction={() => {setStatistics((prevState) =>!prevState)}} searchHandle={(event: React.ChangeEvent<HTMLInputElement>) => {setSearch(event.target.value)}}/>
            { statistics && <ReceiptsStatistics /> }
            <ReceiptsTable receipts={getFilteredReceipts(search, receipts).slice((page - 1) * perPage, perPage * page)} onComment={openComment} onError={openError} onStatus={openStatus} onReturn={() => alert('Deprecated! Не используйте! Устарело!')}/>
            <ReceiptsFooter items={getFilteredReceipts(search, receipts).length} page={page} setPage={(page: number) => setPage(page)} perPage={perPage} onSettings={openSettings}/>

            { popup.show &&
            
                <PopUp title={popup.header ?? ''} show={popup.show} close={() => setPopup({ show: false })}>
                    {popup.content}
                </PopUp>
            }
        </div>
        </>
    )
}

export default Receipts
