import { Color } from "react-bootstrap/esm/types"
import { MaterialSymbol, MaterialSymbolProps } from "react-material-symbols"
import { green } from "../../Services/Colors"

interface ReceiptsHeaderProps {
    statisticOpened?: boolean
    statisticAction?: () => void
    returnBack?: () => void
    searchHandle: (event: React.ChangeEvent<HTMLInputElement>) => void
}
const ReceiptsHeader: React.FC<ReceiptsHeaderProps> = ({statisticOpened, statisticAction, returnBack, searchHandle}) => {
    return (<header>
        <hgroup className="left-area">
            <ReceiptsHeaderIcon icon="keyboard_arrow_left" size={30} action={returnBack}/>
        </hgroup>
        <search>
            <div className="search-area">
                <ReceiptsHeaderIcon icon="search" />
                <input type="text" placeholder="Поиск (#id @человек $сумма !статус)" onChange={(event) => searchHandle(event)}></input>
                <ReceiptsHeaderIcon icon="add" color={green} size={22} action={() => {}} />
            </div>
        </search>
        <hgroup className="right-area">
            <ReceiptsHeaderIcon icon={statisticOpened ? "keyboard_arrow_up" : "keyboard_arrow_down"} size={30} action={statisticAction}/>
        </hgroup>
    </header>)
}
interface ReceiptsHeaderIconProps {
    icon?: MaterialSymbolProps['icon']
    action?: () => void;
    color?: Color;
    size?: number;
    bold?: boolean
}
const ReceiptsHeaderIcon: React.FC<ReceiptsHeaderIconProps> = ({icon, action, color, size, bold}) => {
    return (<div onClick={action} className={`${action ? 'actionable': ''}`}> <MaterialSymbol icon={icon ?? 'error'} size={size ?? 20} color={color} style={bold ? {fontWeight: 'bold'} : {}}/> </div>)
}

export default ReceiptsHeader