import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import config from '../Config';
import { useNavigate } from 'react-router-dom';
import { isLoginExpired } from '../Services/Login';

const Login: React.FC = () => {
  const cookies = new Cookies();
  const navigate = useNavigate();

  const queryParameters = new URLSearchParams(window.location.search);
  const redirect = queryParameters.get("redirect") || '/';
  const code = queryParameters.get("code");

  useEffect(() => {
    if (!code) {
      if (isLoginExpired()) {
        window.location.href = `${config.oauth2_url}`;
      } else {
        authorize();
      }
    } else {
      cookies.set('code', code, { path: '/' });
      authorize();
    }
  }, [code]);

  const authorize = () => {
    fetch(`${config.api_server}/authenticate`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Authorization failed');
        }
      })
      .then(data => {
        cookies.set('last-login', new Date().getTime(), { path: '/' });
        localStorage.setItem('user', JSON.stringify(data));
        navigate(redirect);
      })
      .catch(error => {
        console.error('Error during authorization:', error);
        navigate('/error');
      });
  };

  return <></>;
};

export default Login;