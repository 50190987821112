import React from 'react';
import { ClaimShort } from '../../Models/Claim';
import ClaimItem from './ClaimItem';

interface ClaimsListProps {
    onSelectClaim: (claimID: bigint) => void;
    filteredClaims: ClaimShort[]
};

const ClaimsList: React.FC<ClaimsListProps> = ({ onSelectClaim, filteredClaims}) => {

    return (
        <div className='list'>
            {filteredClaims.map(claim => (
                <ClaimItem claim={claim} onPress={onSelectClaim} />
            ))}
        </div>
    );
};

export default ClaimsList;