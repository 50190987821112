import { useEffect } from "react";
import { MaterialSymbol, MaterialSymbolProps } from "react-material-symbols";

interface PopUpProps {
    show: boolean;
    title_icon?: MaterialSymbolProps['icon'];
    title: string;
    children: React.ReactNode;
    close: () => void;
}
export const PopUp: React.FC<PopUpProps> = ({show, title, title_icon, children, close}) => {
    
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                close();
            }
        };

        if (show) {
            document.addEventListener('keydown', handleKeyDown);
        } else {
            document.removeEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [show, close]);

    return (
        <div className={`action-popup ${show ? 'show' : ''}`}>
            <div className="popup-container">
                <div className="popup-header">
                    <div className="header-title">
                        { title_icon ? 
                            <MaterialSymbol icon={title_icon} />
                        : null }

                        {title}
                    </div>
                    <div className="header-action hover-effect" onClick={close}>
                        <MaterialSymbol icon="close_small" />
                    </div>
                </div>
                <div className="popup-content">
                    {children}
                </div>
            </div>
        </div>
    )
}