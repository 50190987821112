import React, { useEffect } from 'react';
import './Modal.css';

interface ModalProps {
    show: boolean;
    handleClose: () => void;
    children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ show, handleClose, children }) => {
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClose();
            }
        };

        if (show) {
            document.addEventListener('keydown', handleKeyDown);
        } else {
            document.removeEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [show, handleClose]);

    return (
        <div className={`modal ${show ? 'show' : ''}`}>
            <div className="modal-container">
                <div className='modal-header'>
                    <h3> </h3>
                    <span className="close" onClick={handleClose}>&times;</span>
                </div>
                <div className="modal-content">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Modal;