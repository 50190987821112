import React from 'react';
import './Loader.css';

const Loader: React.FC = () => {
  return (
    <div className="loader-container">
      <div className="loader"></div>
    </div>
  );
};

export const SmallLoader: React.FC = () => {
  return (
      <div className="loader-small-container">
        <div className="loader-small"></div>
      </div>
  );
};


export default Loader;