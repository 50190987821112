import React from 'react';
import { LawsuitFormStepProps } from './LawsuitForm';
import { getStatusLabel } from '../../../Services/Convert';

export const LawsuitFormStep5: React.FC<LawsuitFormStepProps> = ({ data, updateData }) => {
    return (
        <>
            <label>Ссылка на Форум</label>
            <input type="text" name="forumLink" value={data.forumLink} onChange={(e) => {updateData({forumLink: e.target.value})}} required />
            {/* todo: дата подачи facepalm */}
            <label>Статус</label>
            <input type="text" name="status" value={getStatusLabel(data.claim.status)} disabled />
            <label>Участники</label>
            <textarea disabled>
              {data.claim.header}
            </textarea>
        </>
    );
};