import { useState } from "react";
import { Claim } from "../../../Models/Claim";
import { IconButton } from "../../../Components/Buttons";
import { MaterialSymbol } from "react-material-symbols";
import '../../../Components/Forms.css'

interface ClaimInfoFormProps {
    claim: Claim;
    submit: (formState: Partial<Claim>) => void;
    close: () => void;
}
  
const ClaimInfoForm: React.FC<ClaimInfoFormProps> = ({ claim, submit, close }) => {
    const [formState, setFormState] = useState({
        number: claim.number,
        type: claim.type,
        side: claim.side,
        forumLink: claim.forumLink ?? '',
        paymentLink: claim.paymentLink ?? ''
    });
  
    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
      const { name, value } = event.target;
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };
  
    const handleFormSubmit = (event: React.FormEvent) => {
      event.preventDefault();
      submit(formState);
      close();
    };
  
    return (
      <form className="form-container" onSubmit={handleFormSubmit}>
        <label htmlFor="type">Инстанция</label>
        <select id="type" name="type" value={formState.type} onChange={handleChange} required>
          <option value="-">Другое</option>
          <option value="SC">Верховный Суд</option>
          <option value="DC">Окружной Суд</option>
          <option value="AC">Апелляционный Суд</option>
          <option value="CA">Кассационная Жалоба</option>
          <option value="CO">Конституционная Жалоба</option>
          <option value="AT">Жалоба в Прокуратуру</option>
        </select>
  
        <label>Номер</label>
        <input type="number" name="number" value={formState.number} onChange={handleChange} required />
  
        <label htmlFor="side">Сторона</label>
        <select id="side" name="side" value={formState.side} onChange={handleChange} required>
          <option value={0}>Защита</option>
          <option value={1}>Обвинение</option>
        </select>
  
        <label>Ссылка на Форум</label>
        <input type="text" name="forumLink" value={formState.forumLink ?? ''} onChange={handleChange} />
  
        <label>Ссылка на Оплату</label>
        <input type="text" name="paymentLink" value={formState.paymentLink ?? ''} onChange={handleChange} />
  
        <div className="button-area">
                <IconButton label="Сохранить" action={() => {submit(formState); close()}} color="#01a049">
                    <MaterialSymbol icon="save" color="white" size={22} />
                </IconButton>
            </div>
      </form>
    );
};
  
export default ClaimInfoForm;