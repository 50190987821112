import React from 'react';
import { LawsuitFormStepProps } from './LawsuitForm';
import { IconButton } from '../../../Components/Buttons';
import { MaterialSymbol } from 'react-material-symbols';
import { green, red } from '../../../Services/Colors';

export const LawsuitFormStep1: React.FC<LawsuitFormStepProps> = ({ data, updateData }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    updateData({ [name]: value });
  };
    // replace handleChange in str 17 with (e) => updateData

  return (
    <>
      <label htmlFor="type">Инстанция</label>
      <select id="type" name="type" value={data.type} onChange={handleChange} required>
        <option value="-">Не выбрано</option>
        <option value="DC">Окружной Суд</option>
        <option value="SC">Верховный Суд</option>
      </select>
      <label htmlFor="advocate">Адвокат</label>
      <select id="advocate" name="advocate" value={data.advocate?.passport} onChange={(event) => updateData({ advocate: data.claim.advocates.find(advocate => advocate.passport === parseInt(event.target.value)) })} required>
        <option value={undefined}>Не выбрано</option>
        {data.claim.advocates.map((advocate) => (
          <option key={advocate.passport} value={advocate.passport}>{advocate.name} ({advocate.passport})</option>
        ))}
      </select>

      <label htmlFor="client">Истец</label>
      <select id="client" name="client" value={data.client?.passport} onChange={(event) => updateData({ client: data.claim.clients.find(client => client.passport === parseInt(event.target.value)) })} required>
        <option value={undefined}>Не выбрано</option>
        {data.claim.clients.map((client) => (
          <option key={client.passport} value={client.passport}>{client.name} ({client.passport})</option>
        ))}
      </select>

      <div id="defendants-label" className='buttoned-label'>
        <label>Ответчик(и)</label>
        <IconButton action={() => updateData({defendants: [...(data.defendants), {name: "", articles: ""}]})} color={green} small={true}>
          <MaterialSymbol icon="add" color="white" size={22} />
        </IconButton>
      </div>
      <div id="defendants" className='buttoned-data'>
        {data.defendants?.map((defendant, index) => (
          <div key={index} className="defendant buttoned-item">
            <input
              type="text"
              name="name"
              value={defendant.name}
              onChange={(e) => {
                const newDefendants = [...data.defendants];
                newDefendants[index].name = e.target.value;
                updateData({defendants: newDefendants});
              }}
              placeholder="Имя Фамилия" 
              required
            />
            <input
              type="text"
              name="articles"
              value={defendant.articles}
              onChange={(e) => {
                const newDefendants = [...data.defendants];
                newDefendants[index].articles = e.target.value;
                updateData({defendants: newDefendants});
              }}
              placeholder="Статьи" 
              required
            />
            <IconButton action={() => {
              const newDefendants = [...data.defendants];
              newDefendants.splice(index, 1);
              updateData({defendants: newDefendants});
            }}
              color={red}
              small={true}
            >
              <MaterialSymbol icon="remove" color="white" size={22} />
            </IconButton>
          </div>
        ))}
      </div>
    </>
  );
};