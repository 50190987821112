import { MaterialSymbol } from "react-material-symbols"
import { IconButton } from "../../../Components/Buttons"
import { green } from "../../../Services/Colors"
import { useState } from "react"
import { Receipt } from "../../../Domains/Receipts"

interface ReceiptsErrorProps {
    receipt: Receipt
    confirm: (updated: Partial<Receipt>) => void
}

const ReceiptsError: React.FC<ReceiptsErrorProps> = ({ receipt, confirm }) => {
    const [formState, setFormState] = useState<{status?: number, comment?: string}>({status: undefined})

    const action = () => {
        if (formState.status !== undefined) {
            if (formState.comment !== undefined) {
                confirm(formState)
            }
        }
    }

    return (<>
        <div className="settings form form-container">
        <div className="settings form-item">
            <label>Причина отмены</label>
            <select name="theme" id="receipt-settings-theme" value={formState.status} onChange={(event) => setFormState({...formState, status: parseInt(event.target.value)})}> 
                <option value={undefined}>Не указана</option>
                <option value={-1}>Ошибка</option>
                <option value={-2}>Бесплатно</option>
                <option value={-3}>Возврат</option>
            </select>
        </div>

        <div className="settings form-item">
            <label>Комментарий</label>
            <textarea onChange={(event) => setFormState({...formState, comment: event.target.value})}>{formState.comment}</textarea>
        </div>
        <IconButton action={action} label="Подтвердить" color={green}>
            <MaterialSymbol icon="save" size={22}></MaterialSymbol>
        </IconButton>
        </div>
    </>)
}

export default ReceiptsError