import { MaterialSymbolProps } from "react-material-symbols";
import { Person } from "./Person"
import { get, post } from "./Server";
import { Color } from "react-bootstrap/esm/types";
import { blue, green, grey, orange, red, violet } from "../Services/Colors";

export interface Receipt {
    id: number,
    amount: number,
    author: Person,
    client: Person,
    ds_id: bigint,
    issued: Date,

    status: number,
    comment?: string,

    updated?: Date,
    updatedBy?: Person,
}

const statusLabels: { [key: string]: {label: string, description: string} } = {
    '-3': {label: 'Возвращено', description: 'Возвращено клиенту в полном объеме согласно соглашению.'},
    '-2': {label:'Бесплатно', description: 'Плата не взимается так как клиент сотрудник бюро.'},
    '-1': {label:'Отменено', description: 'При выписки чека произошла ошибка.'},
    '0': {label:'Не оплачено', description: 'Счет не был оплачен клиентом.'},
    '1': {label:'Переведено', description: 'Полная сумма переведена на счет оплаты или адвоката.'},
    '2': {label:'Оплачено', description: 'Полная сумма зачислена на счет бюро.'},
    '3': {label:'Выплачено', description: 'Вознаграждение выплачено адвокату(-ам).'},
};

const statusLabelsMap: { [key: string]: number } = {
    'Возвращено': -3,
    'Бесплатно': -2,
    'Отменено': -1,
    'Не оплачено': 0,
    'Переведено': 1,
    'Оплачено': 2,
    'Выплачено': 3
};


const statusIcons: { [key: string]: MaterialSymbolProps['icon'] } = {
    '-3': 'currency_exchange',
    '-2': 'credit_card_heart',
    '-1': 'credit_card_off',
    '0': 'credit_card',
    '1': 'credit_score',
    '2': 'account_balance',
    '3': 'assured_workload',
    '101': 'price_check',
    '103': 'checkbook',
    '999': 'timeline'
};

const statusColors: { [key: string]: Color } = {
    '-3': red,
    '-2': violet,
    '-1': red,
    '0': grey,
    '1': orange,
    '2': orange,
    '3': green,
    
    '197': red,
    '198': violet,
    '199': red,
    '200': grey,
    '201': orange,
    '202': blue,
    '203': blue,
};

export function getReceiptStatusLabel(receipt: Receipt): string {
    return statusLabels[receipt.status.toString()].label || 'Неизвестный статус';
}
export function getReceiptStatusIcon(status: string | number): MaterialSymbolProps['icon'] {
    return statusIcons[status.toString()] || 'error';
}
export function getReceiptStatusColor(status: string | number): Color {
    return statusColors[status.toString()] || '';
}
export function getReceiptIssuedLabel(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
        timeZone: 'Europe/Moscow',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    };
    
    const formattedDateTime = date.toLocaleString('ru-RU', options);
    
    const [formattedDate, formattedTime] = formattedDateTime.split(', ');
    
    return `${formattedTime} ${formattedDate}`;
}
export function getReceiptAmountLabel(amount: number): string {
    const formattedAmount = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return `${formattedAmount}$`;
}
export function getReceiptReceivedAmount(receipt: Receipt) {
    let result = receipt.amount;

    if (receipt.status <= 0) {
      result = 0;
    } else if (receipt.status >= 2 && receipt.comment?.includes('Соглашение')) {
      result = receipt.amount;
    } else if (receipt.status < 2) {
      result = 0;
    } else if (receipt.status === 3) {
      result = receipt.amount * 0.2;
    } 

    return parseInt(result.toFixed(0))

}
export const getFilteredReceipts = (search: string, receipts: Receipt[]): Receipt[] => {
    if (!search.trim()) {
        return receipts;
    }

    const searchParts = search.split(' ');

    return searchParts.reduce((filteredReceipts, part) => {
        if (part.startsWith('!')) {
            const statusPart = part.substring(1);
            let status: number;

            if (!isNaN(parseInt(statusPart)) && parseInt(statusPart) >= -3 && parseInt(statusPart) <= 3) {
                status = parseInt(statusPart);
            } else {
                status = statusLabelsMap[statusPart.toLowerCase()] ?? NaN;
            }

            return filteredReceipts.filter(receipt => !isNaN(status) ? receipt.status === status : filteredReceipts);
        }

        if (part.startsWith('#')) {
            const idPart = part.substring(1);
            return filteredReceipts.filter(receipt => idPart ? receipt.id.toString().startsWith(idPart) : filteredReceipts);
        }

        if (part.startsWith('@')) {
            const namePart = part.substring(1).replace('_', ' ').toLowerCase();
            return filteredReceipts.filter(receipt => 
                receipt.author.name.toLowerCase().includes(namePart) || 
                receipt.client.name.toLowerCase().includes(namePart)
            );
        }

        if (part.startsWith('$')) {
            const amountPart = part.substring(2);
            const amount = parseInt(amountPart, 10);
            
            if (part.startsWith('$=')) {
                return filteredReceipts.filter(receipt => receipt.amount === amount);
            }
            if (part.startsWith('$>')) {
                return filteredReceipts.filter(receipt => receipt.amount > amount);
            }
            if (part.startsWith('$<')) {
                return filteredReceipts.filter(receipt => receipt.amount < amount);
            }

            return filteredReceipts;
        }

        if (part.startsWith('^')) {
            const datePart = part.substring(1);
            return filteredReceipts.filter(receipt => new Date(receipt.issued).toISOString().includes(datePart));
        }

        const commentPart = part.toLowerCase();
        return filteredReceipts.filter(receipt => 
            receipt.comment !== undefined && receipt.comment.toLowerCase().includes(commentPart)
        );

    }, receipts);
};

export async function setAPIReceipt(receipt_id: number, updated: Partial<Receipt>) {
    return await post(`/api/receipt/${receipt_id}`, updated)
}

export async function getAPIReceipts(): Promise<Receipt[]> {
    return await get('/api/receipts');
}