import { useEffect, useState } from "react";
import { AdvocateShort, ClaimAdvocate } from "../../../Models/Advocate";
import { IconButton } from "../../../Components/Buttons";
import { MaterialSymbol } from "react-material-symbols";
import '../ClaimDetails.css'
import '../../../Components/Forms.css'
import { getAPIAdvocates } from "../../../Services/Claim";
import { SmallLoader } from "../../../Components/Loader";

interface ClaimAdvocateEditFormProps {
    advocate: ClaimAdvocate;
    submit: (advocate: Partial<ClaimAdvocate>) => void;
    close: () => void;
    remove: () => void;
};

export const ClaimAdvocateEditForm: React.FC<ClaimAdvocateEditFormProps> = ({ advocate, submit, close, remove }) => {
    
    const [formState, setFormState] = useState({
        phone: advocate.phone ?? "",
        passportLink: advocate.passportLink ?? "",
        licenseLink: advocate.licenseLink ?? "",
        signatureLink: advocate.signatureLink ?? "",
    });
  
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
  
    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        submit(formState);
        close();
    };
  
    return (
        <form className="form-container" onSubmit={handleFormSubmit}>
            <label htmlFor="phone">Телефон:</label>
            <input id="phone" name="phone" value={formState.phone} onChange={handleChange} />
  
            <label htmlFor="passportLink">Ссылка на Пасспорт:</label>
            <input id="passportLink" name="passportLink" value={formState.passportLink} onChange={handleChange} />
  
            <label htmlFor="licenseLink">Ссылка на Лицензии:</label>
            <input id="licenseLink" name="licenseLink" value={formState.licenseLink} onChange={handleChange} />
            
            <label htmlFor="signatureLink">Ссылка на Подпись:</label>
            <input id="signatureLink" name="signatureLink" value={formState.signatureLink} onChange={handleChange} />
  
            <div className="button-area">
                <IconButton label="Сохранить" action={() => {submit(formState)}} color="#01a049">
                    <MaterialSymbol icon="save" color="white" size={22} />
                </IconButton>
                <IconButton label="Удалить" action={() => {remove()}} color="#ff0000">
                    <MaterialSymbol icon="person_remove" color="white" size={22} />
                </IconButton>
            </div>
        </form>
    );
};

interface ClaimAdvocateAddFormProps {
    submit: (advocate_id: number) => void;
    close: () => void;
    existingAdvocatesIds: number[];
  };
  
  export const ClaimAdvocateAddForm: React.FC<ClaimAdvocateAddFormProps> = ({ submit, close, existingAdvocatesIds }) => {
    const [advocates, setAdvocates] = useState<AdvocateShort[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState<boolean>(true);
  
    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const fetchedClients = await getAPIAdvocates();
            setAdvocates(fetchedClients);
            setLoading(false);
        };
        fetchData();
    }, []);
  
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };
  
    const filteredAdvocates = advocates.filter(advocate =>
        (advocate.name.toLowerCase().includes(searchTerm.toLowerCase()) || advocate.passport.toString().includes(searchTerm)) &&
        !existingAdvocatesIds.includes(advocate.passport)
    );
  
    if (loading) {
        return <SmallLoader />;
    }
  
    return (
        <form className="form-container" onSubmit={(e) => e.preventDefault()}>
            <label>Выберите клиента</label>
            <input
                type="text"
                placeholder="Поиск"
                value={searchTerm}
                onChange={handleSearchChange}
            />
            <div className="person-list">
                {filteredAdvocates.map(advocate => (
                    <div
                        key={advocate.passport}
                        className="person-item"
                        onClick={() => submit(advocate.passport)}
                    >
                        {advocate.name} ({advocate.passport})
                    </div>
                ))}
            </div>
        </form>
    );
  };