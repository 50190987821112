import React from 'react';
import './NotFound.css';
import config from '../Config';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const NotFound: React.FC = () => {
  const location = useLocation();
  const pathname = location.pathname;

  const handleError = () => {
    if (pathname === '/401' || pathname === '/403' || pathname === '/405' || pathname === '/406') {
      return (
        <div className="error-container">
          <img src={`${config['403_gif']}`} alt="403 GIF" className="error-gif" />
          <p>К сожалению у вас нет доступа к этой странице. Пожалуйста, обратитесь к Грегори.</p>
          <Link to="/" className="back-to-home">Вернуться на главную</Link>
        </div>
      );
    } else {
      return (
        <div className="error-container">
          <img src={`${config['404_gif']}`} alt="404 GIF" className="error-gif" />
          <p>Страница, которую вы ищете, не существует. Пожалуйста, проверьте URL или вернитесь на главную страницу.</p>
          <Link to="/" className="back-to-home">Вернуться на главную</Link>
        </div>
      );
    }
  };

  return handleError();
};

export default NotFound;