import { get, post } from "./Server";
import { Person } from "./Person";

export interface ClaimShort {
    id: number;
    description: string;
    type: string;
    number: number;
    status: number;
    side: number;
    happened: string;
    client: Person;
}
export interface ClaimAdvocate {
    passport: number;
    name: string;
    phone?: string;
    discordName: string;
    passportLink?: string;
    licenseLink?: string;
    signatureLink?: string;
}
export interface ClaimClient {
    passport: number;
    name: string;
    phone?: string;
    discordName: string;
    passportLink?: string;
    agreementNumber: number;
    agreementLink: string;
}
export interface ClaimEvidence {
    id: bigint;
    name: string;
    link: string;
    obtaining?: string;
}
export interface ClaimAddEvidence {
    name: string;
    link: string;
    obtaining?: string;
}
export interface Claim {
    id: bigint;
    number: number;
    type: string;
    description: string;
    happened: Date;
    sent?: Date;
    hearing?: Date;
    status: number;
    side: number;
    header?: string;
    forumLink?: string;
    paymentLink?: string;
    clients: ClaimClient[];
    advocates: ClaimAdvocate[];
    evidences: ClaimEvidence[];
}

export const getFilteredClaims = (search: string, claims: ClaimShort[]): ClaimShort[] => {
    if (!search.trim()) {
        return claims;
    }

    const searchParts = search.split(' ');

    return searchParts.reduce((filteredClaims, part) => {
        if (part.startsWith('!')) {
            const statusPart = part.substring(1);
            let status = parseInt(statusPart);

            return filteredClaims.filter(claim => !isNaN(status) ? claim.status === status : filteredClaims);
        }

        if (part.startsWith('#')) {
            const idPart = part.substring(1);

            return filteredClaims.filter(claim => idPart ? claim.id.toString().startsWith(idPart) : filteredClaims);
        }

        if (part.startsWith('@')) {
            const clientPart = part.substring(1).replace('_', ' ').toLowerCase();
            return filteredClaims.filter(claim => {
                if (claim.client && claim.client.name) {
                    const clientName = claim.client.name.toLowerCase();
                    const clientPass = claim.client.passport.toString()
                    return clientName.includes(clientPart) || clientPass.startsWith(clientPart);
                }

                return false;
            });
        }

        if (part.startsWith('*')) {
            const parts = part.substring(1).split('-');
            const courtPart = parts[0].toLowerCase();
            const numPart = parts.length > 1 ? parts[1] : '';

            filteredClaims = filteredClaims.filter(claim => 
                claim.type.toLowerCase().startsWith(courtPart)
            );

            if (numPart) {
                filteredClaims = filteredClaims.filter(claim => 
                    claim.number.toString().startsWith(numPart)
                );
            }

            return filteredClaims;
        }

        if (part.startsWith('^')) {
            const datePart = part.substring(1);
            return filteredClaims.filter(claim => new Date(claim.happened).toISOString().includes(datePart));
        }

        const description = part.toLowerCase();
        return filteredClaims.filter(claim => 
            claim.description !== undefined && claim.description.toLowerCase().includes(description)
        );

    }, claims);
};

export async function setAPIclaim(claim_id: number, updated: Partial<Claim>) {
    return await post(`/api/claims/${claim_id}`, updated)
};

export async function getAPIclaims(): Promise<ClaimShort[]> {
    return await get('/api/claims');
};

export async function postAPIclaim(claim: Partial<Claim>): Promise<number> {
    const response = await post('/api/claim', {
        description: claim.description ?? "",
        type: '-',
        number: 0,
        status: 0,
        happened:  claim.happened ? claim.happened.getTime() : new Date().getTime()
    })

    return parseInt(response);
}