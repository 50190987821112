import './Profile.css';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAPIProfile, Profile as _profile, setAPIProfile } from "../../Domains/Profile";
import { getCurrentUser } from "../../Domains/Person";
import Loader from "../../Components/Loader";

export const Profile: React.FC = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<_profile>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const user = getCurrentUser();
    if (user) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const fetchedProfile = await getAPIProfile(user.passport);
          setFormData(fetchedProfile);
        } catch (error) {
          navigate(`/login?redirect=/?page=1`);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    } else {
      navigate(`/login?redirect=/?page=1`);
    }
  }, [navigate]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }) as _profile);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (formData) {
      setAPIProfile(formData.passport, {
        name: formData.name,
        phone: formData.phone,
        passlink: formData.passlink,
        licenselink: formData.licenselink,
      });
      navigate(`/?page=1`);
      window.location.reload();
    }
  };

  return (
    <>
      <h2>Профиль</h2>
      {loading ? (
        <Loader />
      ) : formData ? (
        <form onSubmit={handleSubmit}>
          <div className="group">
            <div className="field">
              <label>Имя</label>
              <input name="name" value={formData.name} onChange={handleChange} />
            </div>
            <div className="field">
              <label>ID</label>
              <input name="passport" value={formData.passport} disabled />
            </div>
            <div className="field">
              <label>Телефон</label>
              <input name="phone" value={formData.phone} onChange={handleChange} />
            </div>
          </div>

          <div className="group">
            <div className="field">
              <label>Вступление</label>
              <input type={formData.joined ? "date" : ''} name="joined" value={formData.joined ? new Date(formData.joined).toISOString().slice(0, 10) : ''} disabled />
            </div>
            <div className="field">
              <label>Отставка</label>
              <input type={formData.resigned ? "date" : ''} name="resigned" value={formData.resigned ? new Date(formData.resigned).toISOString().slice(0, 10) : ''} disabled />
            </div>
            <div className="field">
              <label>Должность</label>
              <select name="role" value={formData.role} disabled>
                <option value={-2}>Менеджер</option>
                <option value={-1}>Управляющий Партнер</option>
                <option value={0}>Партнер</option>
                <option value={1}>Партнер</option>
              </select>
            </div>
          </div>

          <div className="group">
            <div className="field">
              <label>Лицензии</label>
              <input name="licenselink" value={formData.licenselink} onChange={handleChange} />
            </div>
            <div className="field">
              <label>Паспорт</label>
              <input name="passlink" value={formData.passlink} onChange={handleChange} />
            </div>
          </div>
          <button type="submit" className="actionable">Сохранить</button>
        </form>
      ) : (
        <Loader />
      )}
    </>
  );
};
