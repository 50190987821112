import React, { useState } from 'react';
import { LawsuitFormStepProps } from './LawsuitForm';
import { IconButton } from '../../../Components/Buttons';
import { MaterialSymbol } from 'react-material-symbols';
import { green } from '../../../Services/Colors';
import { generateLawsuit, Warning } from '../../../Services/Lawsuit';



export const LawsuitFormStep4: React.FC<LawsuitFormStepProps> = ({ data, updateData }) => {
    const [generatedLawsuit, setGeneratedLawsuit] = useState<string>();
    const [warnings, setWarnings] = useState<Warning[]>(data.warnings);

    const createLawsuit = () => {
        const newWarnings: Warning[] = [];

        if (data.type == '-') {
            newWarnings.push({type: 'critical', message: 'Инстанция не выбрана.'})
        }

        if (data.advocate == undefined) {
            newWarnings.push({type: 'critical', message: 'Адвокат не выбран.'})
        } else if (data.advocate.passportLink == undefined || data.advocate.phone == undefined || data.advocate.licenseLink  == undefined) {
            newWarnings.push({type: 'miscellaneous', message: 'Паспорт, телефон или лицензия адвоката не указаны.'})
        } else if (data.advocate.passportLink == "" || data.advocate.phone  == "" || data.advocate.licenseLink  == "") {
            newWarnings.push({type: 'miscellaneous', message: 'Паспорт, телефон или лицензия адвоката не указаны.'})
        }

        if (data.client == undefined) {
            newWarnings.push({type: 'critical', message: 'Истец не выбран.'})
        } else if (data.client.passportLink == undefined || data.client.phone == undefined || data.client.agreementLink == undefined) {
            newWarnings.push({type: 'miscellaneous', message: 'Паспорт, телефон или соглашение истца не указаны.'})
        } else if (data.client.passportLink == "" || data.client.phone == "" || data.client.agreementLink == "") {
            newWarnings.push({type: 'miscellaneous', message: 'Паспорт, телефон или соглашение истца не указаны.'})
        }

        if (data.defendants.length <= 0) {
            newWarnings.push({type: 'critical', message: 'Ответчик не выбран.'})
        } else {
            data.defendants.map((defendant, index) => {
                if (defendant.name == '') {
                    newWarnings.push({type: 'critical', message: 'Имя ответчика не указано.'})
                } else if (defendant.articles == '') {
                    newWarnings.push({type: 'critical', message: 'Наршения ответчика не указаны.'})
                }
            })
        }

        if (data.evidences.length <= 0) {
            newWarnings.push({type: 'critical', message: 'Доказательств недостаточно.'})
        }

        if ((data.number <= 200 && data.type == 'SC') || (data.number <= 1800 && data.type == 'DC')) {
            newWarnings.push({type: 'miscellaneous', message: 'Номер иска слишком мал для данной инстанции.'})
        }

        newWarnings.push({type: 'informative', message: 'Оплатить пошлину.'})
        newWarnings.push({type: 'informative', message: 'Проверить описание и статьи.'})
        newWarnings.push({type: 'informative', message: 'Проверить требования и данные.'})

        updateData({ warnings: newWarnings })
        setWarnings(newWarnings)
        const { string, header } = generateLawsuit(data)

        // const currentDate = new Date();
        // console.log(`${currentDate.getTime() / 60000} - ${currentDate.getTimezoneOffset() } + 180 = `)
        // const sentDate = new Date(currentDate.getTime() + (currentDate.getTimezoneOffset() + 180) * 60000).toISOString().slice(0, 16) + "Z"
        updateData({ claim: {...data.claim, header: header, status: 3, side: 1} })

        setGeneratedLawsuit(string)
    }

    return (
        <>
            <label>Номер Иска</label>
            <input type="number" name="number" value={data.number} onChange={(e) => {updateData({number: parseInt(e.target.value)})}} required />
            {
                generatedLawsuit != undefined ? 
                <>
                <label>Код  </label>
                <textarea disabled>
                    {generatedLawsuit}
                </textarea>
                <IconButton label='Скопировать' action={() => navigator.clipboard.writeText(generatedLawsuit)} >
                    <MaterialSymbol icon="content_copy" color="white" size={22} />
                </IconButton>
                </>
                :
                <>
                <IconButton label='Сгенерировать' action={createLawsuit} color={green}>
                    <MaterialSymbol icon="gavel" color="white" size={22} />
                </IconButton>
                </>
            }
            {
                warnings.length > 0 ? 
                <>
                    <label>2-Do</label>
                    <div id='warnings'>
                        { warnings.map((warning, index) => 
                            <div className={`warning ${warning.type}`}>
                                <input className='warning-status' type="checkbox" name={`warning-${index}`} checked={warning.checked} onChange={(e) => {
                                    const newWarnings = [...data.warnings];
                                    newWarnings[index].checked = e.target.checked;
                                    updateData({warnings: newWarnings});
                                }} />
                                <label className='warning-message'>{warning.message}</label>
                            </div>
                            )
                        }
                    </div>
                </>
                : 
                <></>
            }
        </>
    );
};