import { ClaimShort } from "../../Models/Claim";
import { calculateHousSince } from "../../Services/Time";
import './ClaimItem.css'

interface ClaimItemProps {
    claim: ClaimShort;
    onPress: (claimID: bigint) => void;
}

const convertDisplayableTime = (happened: string): string => {
    const hoursDifference = calculateHousSince(new Date(happened))

    if (hoursDifference < 48) {
        return `${hoursDifference}h`;
    } else {
        const daysDifference = Math.floor(hoursDifference / 24);
        return `${daysDifference}d`;
    }

};

const getClassBasedOnTimeAndStatus = (status: number, hours: number): string => {
    if (status == -4 || status == -3 || status == -2 || status ==  -1 || status == 6) {
        return 'c1'
    }

    if (status == 0 || status == 1 || status == 2 ) {
        if (hours <= 24) {
        return 'c2'
        } else {
        return 'c3'
        }
    }

    if (status == 3 || status == 4) {
        return 'c4'
    }

    if (status == 5) {
        return 'c5'
    }

    return ''

};

const ClaimItem: React.FC<ClaimItemProps> = ({ claim, onPress }) => {
    const hoursSinceHappened = Math.floor((new Date().getTime() - new Date(claim.happened).getTime()) / (1000 * 60 * 60));
    const description = claim.description.split('\n')[0]
    const claimLabel = claim.type !== '-' ? `${claim.type}-${claim.number}` : `${claim.number}`;

    return (
        <div className={`claim-item ${getClassBasedOnTimeAndStatus(claim.status, hoursSinceHappened)}`} onClick={() => onPress(claim.id)} >
            <div className="claim-item-header">
                <span className="claim-item-label">({claim.id.toString()}) {claimLabel} [{claim.side == 1 ? "О" : "З"}]</span>
                <span className="claim-item-time">{convertDisplayableTime(claim.happened)}</span>
            </div>
            <div className="claim-item-body">
                <p className="claim-item-description">{description}</p>
                <span className="claim-item-client">{claim.client.name ?? 'N/A'}</span>
            </div>
        </div>
    );
};

export default ClaimItem