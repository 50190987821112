import React, { useState } from 'react';
import '../ClaimDetails.css'
import '../../../Components/Forms.css'

interface StatusFormProps {
    currentStatus: number;
    submit: (status: number) => void;
    close: () => void;
}

export const StatusForm: React.FC<StatusFormProps> = ({ currentStatus, submit, close }) => {
    const [status, setStatus] = useState(currentStatus);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setStatus(parseInt(event.target.value));
    };

    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        
        submit(status);
        close();
    };

    return (
        <form className="form-container" onSubmit={handleFormSubmit}>
            <select id="status" name="status" value={status} onChange={handleChange}>
                <option value={0}>Создано</option>
                <option value={1}>Взято в работу</option>
                <option value={2}>Материалы Собраны</option>
                <option value={3}>Иск Подан</option>
                {/* <option value={4}>Расследование Завершено</option> */}
                <option value={5}>Суд Назначен</option>
                <option value={6}>Выиграно</option>
                <option value={-1}>Отменено</option>
                <option value={-2}>Отклонено</option>
                <option value={-3}>Проиграно</option>
                <option value={-4}>Остановлено (Мировое Соглашение)</option>
            </select>
            <button type="submit">Подтвердить</button>
        </form>
    );
};

interface DescriptionFormProps {
    currentDescription: string;
    submit: (description: string) => void;
    close: () => void;
}

export const DescriptionForm: React.FC<DescriptionFormProps> = ({ currentDescription, submit, close }) => {
    const [shortDescription, setShortDescription] = useState(currentDescription.split('\n')[0] || '');
    const [fullDescription, setFullDescription] = useState(currentDescription.split('\n').slice(1).join('\n') || '');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        if (name === 'fullDescription') {
            setFullDescription(value);
        } else if (name === 'shortDescription') {
            setShortDescription(value);
        }
    };

    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const updatedDescription = `${shortDescription}\n${fullDescription}`;
        submit(updatedDescription);
        close();
    };

    return (
        <form className="form-container" onSubmit={handleFormSubmit}>
            <label>Кратко:</label>
            <input type="text" name="shortDescription" value={shortDescription} onChange={handleChange} required />

            <label>Подробно:</label>
            <textarea name="fullDescription" value={fullDescription} onChange={handleChange} style={{ height: "200px" }} />
            <button type="submit">Подтвердить</button>
        </form>
    );
};

interface HeaderFormProps {
    currentHeader: string;
    submit: (header: string) => void;
    close: () => void;
}

export const HeaderForm: React.FC<HeaderFormProps> = ({ currentHeader, submit, close }) => {
    const [header, setHeader] = useState(currentHeader);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setHeader(event.target.value);
    };

    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        
        submit(header);
        close();
    };

    return (
        <form className="form-container" onSubmit={handleFormSubmit}>
            <textarea name="header" value={header} onChange={handleChange} style={{ height: "100px" }} />
            <button type="submit">Подтвердить</button>
        </form>
    );
};

interface ForumLinkFormProps {
    submit: (forumLink: string) => void;
    close: () => void;
}

export const ForumLinkForm: React.FC<ForumLinkFormProps> = ({ submit, close }) => {
    const [link, setLink] = useState('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setLink(event.target.value);
    };

    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        
        submit(link);
        close();
    };

    return (
        <form className="form-container" onSubmit={handleFormSubmit}>
            <input type="text" name="link" value={link} onChange={handleChange}/>
            <button type="submit">Подтвердить</button>
        </form>
    );
};