import React, { useState, useEffect } from 'react';
import '../../../Components/Forms.css'

interface DateFormProps {
    currentDate: Date | null | undefined | number;
    submit: (date: Date) => void;
    close: () => void;
}

const DateForm: React.FC<DateFormProps> = ({ currentDate, submit, close }) => {
    const [date, setDate] = useState<string>("");

    useEffect(() => {
        if (currentDate) {
            currentDate = new Date(currentDate)
            if (currentDate.getTime() == currentDate.getTime()) {
                const localDate = new Date(currentDate.getTime() - currentDate.getTimezoneOffset() * 60000).toISOString().slice(0, 16);
                setDate(localDate);
            }
        }
    }, [currentDate]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDate(event.target.value);
    };

    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if (date) {
            const submittedDate = new Date(date + "Z");
            submit(submittedDate);
        }
        close();
    };

    return (
        <form className="form-container" onSubmit={handleFormSubmit}>
            <input
                type="datetime-local"
                name="date"
                value={date}
                onChange={handleChange}
                min={"2024-01-01T00:00"}
                max={"2025-01-01T00:00"}
                required
            />
            <button type="submit">Подтвердить</button>
        </form>
    );
};

export default DateForm;