import React from 'react';
import { MaterialSymbol } from 'react-material-symbols';
import { blue, grey } from '../Services/Colors';

interface PaginatorProps {
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

const Paginator: React.FC<PaginatorProps> = ({ totalItems, itemsPerPage, currentPage, onPageChange }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePrevClick = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handlePageClick = (page: number) => {
    onPageChange(page);
  };

  return (
    <div style={{display: 'flex', gap: 15}}>
        <div onClick={() => currentPage == 1 ? undefined : handlePrevClick()} className='actionable' style={{alignItems: "center"}} >
            <MaterialSymbol icon="chevron_left" size={22} color={ currentPage == 1 ? grey : blue}></MaterialSymbol>
        </div>
        <div style={{display: 'flex', gap: 10}}>
            {Array.from({ length: totalPages }, (_, i) => (
                <div key={i + 1} onClick={() => handlePageClick(i + 1)}  className='actionable' style={{color: currentPage == i + 1 ? blue : ''}}> {i + 1} </div>
            ))}
        </div>
        <div onClick={() => currentPage === totalPages ? undefined : handleNextClick()} className='actionable' style={{alignItems: "center"}} >
            <MaterialSymbol icon="chevron_right" size={22} color={ currentPage == totalPages ? grey : blue}></MaterialSymbol>
        </div>
    </div>
  );
};

export default Paginator;