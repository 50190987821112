export const calculateHousSince = (date: Date): number => {
  return Math.floor((new Date().getTime() - date.getTime()) / (1000 * 60 * 60));
};

export function calculateTimeSince(pastDate: Date): string {
  const hours = calculateHousSince(pastDate);

  if (hours > 72) {
    return `${Math.round(hours / 24).toString()}d`
  } else if (hours > 0) {
    return `${hours.toString()}h`
  } else {
    return `!?`
  }
}

const getWeekdayName = (date: Date): string => {
  const weekdays = [
    'Вс', 'Пн', 'Вт', 'Ср', 
    'Чт', 'Пт', 'Сб'
  ];
  return weekdays[date.getDay()];
};

export const formatClaimDate = (date: Date | null | undefined): string => {
  if (date == null || date == undefined || date.getTime() != date.getTime()) {
    return 'N/A';
  }
  
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  
  const formattedDate = `${day}.${month}.${year} (${getWeekdayName(date)})`;
  const formattedTime = `${hours}:${minutes}`;
  
  return `${formattedDate}\n${formattedTime}`;
};

export function timeSinceMoscowLabel(date: Date): string {
  const moscowTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const nowMoscow = new Date().toLocaleString('en-US', { timeZone: moscowTimezone });

  const dateMs = date.getTime();
  const nowMoscowMs = new Date(nowMoscow).getTime();

  const differenceMs = nowMoscowMs - dateMs;
  const hoursDifference = Math.round(differenceMs / (1000 * 60 * 60));

  if (hoursDifference > 120 ){
    const daysDifference = hoursDifference / 24
    return `${daysDifference.toFixed(2)} дней`;
  } else {
    return `${hoursDifference} часов`;
  }
  
}

export function timeBetweenDatesLabel(startDate: Date, endDate: Date): string {
  const timeDifference = endDate.getTime() - startDate.getTime();
  const hoursDifference = Math.round(timeDifference / (1000 * 60 * 60));

  if (hoursDifference > 120 ){
    const daysDifference = hoursDifference / 24
    return `${daysDifference.toFixed(2)} дней`;
  } else if (hoursDifference <= 0) {
    return '???'
  } else {
    return `${hoursDifference} часов`;
  }

}
export function getCurrentMoscowTime(): Date {
  const moscowTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const nowMoscow = new Date().toLocaleString('en-US', { timeZone: moscowTimezone });

  return new Date(nowMoscow);
}


