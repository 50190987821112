import { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchHeader from "../../Components/Headers";
import { getAPIReceipts, getFilteredReceipts, Receipt, setAPIReceipt } from "../../Domains/Receipts";
import ReceiptsTable from "../Receipts/ReceiptsTable";
import ReceiptsFooter from "../Receipts/ReceiptsFooter";
import { PopUp } from "../../Components/PupUp";
import ReceiptsError from "../Receipts/Modals/ReceiptsError";
import ReceiptsComment from "../Receipts/Modals/ReceiptsComment";
import Loader from "../../Components/Loader";

export const Finances: React.FC = () => {
  const navigate = useNavigate();

  const [receipts, setReceipts] = useState<Receipt[]>([]);
  const [filteredReceipts, setFilteredReceipts] = useState<Receipt[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  
  const perPage = 20;
  const [page, setPage] = useState<number>(1);
  const [view, setView] = useState<number>(1);
  const [query, setQuery] = useState<string>('');
  const [popup, setPopup] = useState<{ show: boolean; header?: string; content?: ReactNode }>({ show: false });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const fetchedReceipts = await getAPIReceipts();
        if (fetchedReceipts.toString() == '406') {
          navigate('/406')
        }
        setReceipts(fetchedReceipts);
        setFilteredReceipts(fetchedReceipts);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setFilteredReceipts(getFilteredReceipts(query, receipts));
  }, [query, receipts]);

  const openError = (receipt: Receipt) => {
    setPopup({
      show: true,
      header: `Отмена чека #${receipt.id}`,
      content: <ReceiptsError receipt={receipt} confirm={(updated) => confirmError(receipt.id, updated)} />,
    });
  };

  const confirmError = (receipt_id: number, updated: Partial<Receipt>) => {
    setAPIReceipt(receipt_id, updated);
    setPopup({ show: false });

    const receiptIndex = receipts.findIndex(r => r.id === receipt_id);
    const updatedReceipts = [...receipts];
    updatedReceipts[receiptIndex] = { ...updatedReceipts[receiptIndex], ...updated };
    setReceipts(updatedReceipts);
  };

  const openComment = (receipt: Receipt) => {
    setPopup({
      show: true,
      header: `Комментарий к чеку #${receipt.id}`,
      content: <ReceiptsComment receipt={receipt} confirm={(updated) => confirmComment(receipt.id, updated)} />,
    });
  };

  const confirmComment = (receipt_id: number, updated: Partial<Receipt>) => {
    setAPIReceipt(receipt_id, updated);
    setPopup({ show: false });

    const receiptIndex = receipts.findIndex(r => r.id === receipt_id);
    const updatedReceipts = [...receipts];
    updatedReceipts[receiptIndex] = { ...updatedReceipts[receiptIndex], ...updated };
    setReceipts(updatedReceipts);
  };

  const openStatus = (receipt: Receipt) => {
    if (receipt.comment?.includes('Соглашение') && receipt.status == 1) {
      setAPIReceipt(receipt.id, { status: receipt.status + 2 });
    } else {
      setAPIReceipt(receipt.id, { status: receipt.status + 1 });
    }

    const receiptIndex = receipts.findIndex(r => r.id === receipt.id);
    const receiptToUpdate = receipts[receiptIndex];
    let updatedReceipt;
    if (receipt.comment?.includes('Соглашение') && receipt.status == 1) {
      updatedReceipt = { ...receiptToUpdate, status: receiptToUpdate.status + 2 };
    } else {
      updatedReceipt = { ...receiptToUpdate, status: receiptToUpdate.status + 1 };
    }
    const updatedReceipts = [...receipts];
    updatedReceipts[receiptIndex] = updatedReceipt;
    setReceipts(updatedReceipts);
  };

  const openReturn = (receipt: Receipt) => {
    const receiptIndex = receipts.findIndex(r => r.id === receipt.id);
    const receiptToUpdate = receipts[receiptIndex];
    let updatedReceipt;


    if (receipt.comment?.includes('Соглашение') && receipt.status == 3) {
      setAPIReceipt(receipt.id, { status: receipt.status - 2 });
      updatedReceipt = { ...receiptToUpdate, status: receiptToUpdate.status - 2 };
    } if (receipt.status < 0) {
      setAPIReceipt(receipt.id, { status: 0 });
      updatedReceipt = { ...receiptToUpdate, status: 0 };
    } else {
      setAPIReceipt(receipt.id, { status: receipt.status - 1 });
      updatedReceipt = { ...receiptToUpdate, status: receiptToUpdate.status - 1 };
    }
    
    const updatedReceipts = [...receipts];
    updatedReceipts[receiptIndex] = updatedReceipt;
    setReceipts(updatedReceipts);
  }

  return (
    <>
      <h2>Финансы</h2>
      {loading ? (
        <Loader />
      ) : (
        <div className="hp-vertical-box">
          <SearchHeader
            icon_main="add"
            color_main="green"
            search={(event: React.ChangeEvent<HTMLInputElement>) => setQuery(event.target.value)}
            search_placeholder="Поиск (#id @человек !статус $сумма)"
            icon_right={view === 0 ? "analytics" : view === 1 ? "database" : "receipt"}
            action_right={() => setView(view === 2 ? 0 : view + 1)}
          />
          {receipts.length === 0 && <p>Ничего не найдено.</p>}
          {view === 1 && (
            <>
              <ReceiptsTable
                receipts={filteredReceipts.slice((page - 1) * perPage, perPage * page)}
                onComment={openComment}
                onError={openError}
                onStatus={openStatus}
                onReturn={openReturn}
            />
              <ReceiptsFooter
                items={filteredReceipts.length}
                page={page}
                setPage={(page: number) => setPage(page)}
                perPage={perPage}
              />
              {popup.show && (
                <PopUp title={popup.header ?? ''} show={popup.show} close={() => setPopup({ show: false })}>
                  {popup.content}
                </PopUp>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};
