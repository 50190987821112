import './Hearings.css';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MaterialSymbol } from 'react-material-symbols';
import { getAPIhearings, getHearingDateIcon, Hearing } from '../../Domains/Hearings';
import { formatClaimDate } from '../../Services/Time';
import Loader from '../../Components/Loader';

export const Hearings: React.FC = () => {
  const [events, setEvents] = useState<Hearing[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const fetchedEvents = await getAPIhearings();
        setEvents(fetchedEvents);
      } catch (error) {
        navigate('/login?redirect=/?page=2');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  const handleEventClick = (event: Hearing) => {
    const url = new URL(window.location.href);
    if (event.id){
      url.searchParams.set("id", event.id.toString());
    }
    window.location.href = url.toString();
  };

  const renderEventContent = (event: Hearing) => {
    const participants = event.participants.map((participant: any) => {
      const nameParts = participant.name.split(' ');
      return `${nameParts[0][0]}. ${nameParts[1]}`;
    }).join(', ');

    const icon = getHearingDateIcon(event);

    return (
      <tr key={event.id}>
        <td><MaterialSymbol icon={icon.icon} color={icon.color} size={21} /></td>
        <td>{formatClaimDate(new Date(event.datetime))}</td>
        <td>
          <span className='event-label' onClick={() => handleEventClick(event)}>
            {event.label}
          </span>
        </td>
        <td>{participants}</td>
      </tr>
    );
  };

  return (
    <>
      <h2>Суды</h2>
      <div className="calendar-container">
        {loading ? (
          <Loader />
        ) : (
          <table className="hearings-table">
            <thead>
              <tr>
                <th><MaterialSymbol icon='schedule' /></th>
                <th>Время</th>
                <th>Дело</th>
                <th>Участники</th>
              </tr>
            </thead>
            <tbody>
              {events.length > 0 ? 
                events.map(renderEventContent)
                :
                <tr className='hearings-notfound'>
                  <td colSpan={4}>В ближайшее время судов не запланированно.</td>
                </tr>
              }
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};
