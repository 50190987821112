import { useState } from "react"
import { Receipt } from "../../../Domains/Receipts"
import { IconButton } from "../../../Components/Buttons"
import { MaterialSymbol } from "react-material-symbols"
import { green } from "../../../Services/Colors"

interface ReceiptsCommentProps {
    receipt: Receipt
    confirm: (updated: Partial<Receipt>) => void
}

const ReceiptsComment: React.FC<ReceiptsCommentProps> = ({ receipt, confirm }) => {
    const [formState, setFormState] = useState<{comment?: string}>({ comment: receipt.comment })

    const action = () => {
        if (formState.comment !== undefined) {
            if (formState.comment !== '') {
                confirm(formState)
            }
        }
    }

    return (<>
        <div className="settings form form-container">

        <div className="settings form-item">
            <label>Комментарий к чеку</label>
            <textarea onChange={(event) => setFormState({...formState, comment: event.target.value})}>{formState.comment}</textarea>
        </div>
        <IconButton action={action} label="Подтвердить" color={green}>
            <MaterialSymbol icon="save" size={22}></MaterialSymbol>
        </IconButton>
        </div>
    </>)
}

export default ReceiptsComment