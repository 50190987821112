import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ClaimNew, ClaimShort } from '../../Models/Claim';
import Modal from '../../Components/Modal';
import CreateClaimForm from './ClaimForms/CreateClaimForm';
import { BookmarkIcon, CloseIcon } from '../../Components/Icons';
import ClaimDetails from './ClaimDetails';
import ClaimsList from './ClaimList';
import Loader from '../../Components/Loader';

import './Claims.css';
import { addAPIClaim } from '../../Services/Claim';
import { getAPIClaims, isBookmarked } from '../../Services/Claims';

const Claims: React.FC = () => {
  const [claims, setClaims] = useState<ClaimShort[]>([]);
  const [filteredClaims, setFilteredClaims] = useState<ClaimShort[]>([]);

  const queryParameters = new URLSearchParams(window.location.search);
  const queryID = queryParameters.get("id");

  const navigate = useNavigate();

  const [selectedClaim, setSelectedClaim] = useState<bigint | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isBookmarks, setIsBookmarks] = useState<boolean>(false);

  const handleClaimSelect = (claimID: bigint): void => {
    setSelectedClaim(claimID);
  };

  const handleBackClick = () => {
    setSelectedClaim(null);
    navigate('/');
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleCreateButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const onBookmarkClick = () => {
    setIsBookmarks(prev => !prev);
  };

  const handleCreateClaim = async (newClaim: ClaimNew) => {
    const fetchData = async () => {
      try {
        const claim_id = await addAPIClaim(newClaim);

        const url = new URL(window.location.href);
        url.searchParams.set("id", claim_id.toString());
        window.location.href = url.toString();
      } catch (error) {
        navigate("/login/");
      }
    };
    fetchData();
  };

  useEffect(() => {
    const fetchData = async () => {
      const fetchedClaims = await getAPIClaims();
      setClaims(fetchedClaims);
      setFilteredClaims(fetchedClaims)
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (isBookmarks){
      setFilteredClaims(claims.filter(claim => isBookmarked(claim.id)).filter(claim => claim.description.includes(searchTerm)))
    } 
    else {
      setFilteredClaims(claims.filter(claim => claim.description.includes(searchTerm)))
    }
  }, [searchTerm, isBookmarks]);

  if (claims) {
    return (
      <div className="container">
            <div className="column-left">
              <div className="header">
                <CloseIcon onClick={handleBackClick} />
                <input
                  className="search-field"
                  type="text"
                  placeholder="Поиск..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <BookmarkIcon onClick={onBookmarkClick} fill={isBookmarks} />
              </div>
              <ClaimsList
                onSelectClaim={handleClaimSelect}
                filteredClaims={filteredClaims}
              />
              <button className="create-button" onClick={handleCreateButtonClick}>
                Создать
              </button>
            </div>
            <div className="column-right">
              <ClaimDetails claim_id={selectedClaim == null ? queryID == null ? undefined : BigInt(queryID) : selectedClaim} />
            </div>
            <Modal show={isModalOpen} handleClose={handleModalClose}>
              <CreateClaimForm handleSubmit={handleCreateClaim} handleClose={handleModalClose} />
            </Modal>
      </div>
    );
  } else {
    return <Loader />
  }
};

export default Claims;