export interface Person {
    passport: number,
    name: string
}

export const getCurrentUser = (): Person | undefined => {
    const userString = localStorage.getItem('user');
  
    if (userString) {
      try {
        const user: Person = JSON.parse(userString);
        return { name: user.name, passport: user.passport };
      } catch (error) {
        return undefined;
      }
    } else {
      return undefined;
    }
};