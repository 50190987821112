import config from "../Config";
import { Motion } from "../Core/Claim/ClaimMotion/MotionForms";
import { getCourtName } from "./Convert";

export function generateMotion(motion: Motion): string {
    const formattedDate = (date: Date) => date.toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  
    
    const to = `[RIGHT]В ${getCourtName(motion.claim.type)} Штата Сан-Андреас\n90001, г. Лос-Сантос, Рокфорд-хиллз, Рокфорд-драйв\n`
    const header = motion.claim.header
    const heading = `[/RIGHT] \n\n[CENTER][B][SIZE=5]Ходатайство[/SIZE][/B][/CENTER] \n`
    let beginning = `Я, партнер частного адвокатского бюро "MBA Legal Group", [COLOR=rgb(65, 105, 225)]${motion.advocate?.name}[/COLOR], ходатайствую `
    let evidences = ''
    
    switch (motion.option) {
        case 'Привлечение':
            beginning += `о привлечении меня в качестве представителя ${motion.client?.name}.`
            evidences += `[URL='${motion.client?.agreementLink}'][COLOR=rgb(65, 105, 225)][Соглашение][/COLOR][/URL]`
            break;
        case 'Приобщение':
            beginning += 'о приобщении следущих доказательств к материалам искового заявления.'
            evidences = motion.evidences?.map((evidence) => `[URL='${evidence.link}'][COLOR=rgb(65, 105, 225)][${evidence.name}][/COLOR][/URL]${evidence.obtaining ? ` (( [URL='${evidence.obtaining}']Получение[/URL] ))` : '' }`).join(', ');
            break;
        case 'Изменение':
            beginning += 'о редакции искового заявления.'
            break;
        case 'Перенос':
            beginning += 'о переносе судебного заседания в зал судебных заседании мэрии Лос-Сантоса/Округа Блэйн 00.00.2024 в 00:00.'
            break;
        default:
            beginning += '...'
            break;
    }

    const attachments = `\n[B]Перечень прилагаемых документов:[/B] \n`
    const at_advocate_id = `1. Паспорт: [URL='${motion.advocate?.passportLink}'][COLOR=${motion.advocate?.passportLink ? "rgb(65, 105, 225)" : 'rgb(226, 80, 65)'}][Гиперссылка][/COLOR][/URL] \n`
    const at_evdences = `2. Доказательства: ${evidences}\n`
    const at_advocate_contact = `3. Контакты: [COLOR=rgb(65, 105, 225)]${motion.advocate?.discordName ?? 'ДИСКОРД'}@sa.com ${motion.advocate?.phone ?? 'ТЕЛЕФОН'}[/COLOR] \n`
    const footer = `[RIGHT][COLOR=rgb(65, 105, 225)]${formattedDate(new Date())} \n[IMG]${config.api_server}/source/signature/${motion.advocate?.passport}[/IMG][/COLOR][/RIGHT]`
    
    const string = `${to}${header}${heading}${beginning}\n${attachments}${at_advocate_id}${at_evdences}${at_advocate_contact}${footer}`;
  
    return string
}