import React, { ReactNode } from 'react';
import './Buttons.css';
import { Color } from 'react-bootstrap/esm/types';

interface ButtonDefault {
  onPress: () => void;
}

export const LoginButton: React.FC<ButtonDefault> = ({ onPress }) => {
  return (
    <div className="login-button-container">
      <button className="login-button" onClick={() => onPress()}>Войти</button>
    </div>
  );
};

interface IconButtonProps {
  label?: string;
  children?: ReactNode;
  action: () => void;
  color?: Color;
  small?: boolean;
}
export const IconButton: React.FC<IconButtonProps> = ({label, children, action, color, small}) => {
  return (
    <div className={`icon-button-container`}>
      <div className={`icon-button${small ? '-small' : ''} hover-effect`} onClick={action} style={{ backgroundColor: color }}>
          { children ?
            <div className="icon">
              { children }
            </div>
            : null }
          { label ? 
              <div className="label" style={{ marginLeft: children ? '0.5rem' : '0px' }}>
                {label}
              </div>
          : null }
      </div>
    </div>
  )
}
