import { MaterialSymbol, MaterialSymbolProps } from "react-material-symbols";

interface ClaimDetailsContentAreaProps {
    title: string;
    icon_title: MaterialSymbolProps['icon'];
    action?: () => void;
    icon_action?: MaterialSymbolProps['icon'];
    children: React.ReactNode;
}
export const ClaimDetailsContentArea: React.FC<ClaimDetailsContentAreaProps> = ({title, icon_title, action, icon_action, children}) => {
    return (
        <div className="content-area">
            <div className="content-area-header">
                <div className="header-title">
                    <MaterialSymbol icon={icon_title} size={20} /> {title}
                </div>
                { icon_action ? 
                    <div className="header-action hover-effect" onClick={action}>
                        <MaterialSymbol icon={icon_action} size={18} />
                    </div>
                : null}
            </div>
            <div className="content-area-children">{children}</div>
        </div>
    )
}