export const getStatusDescription = (status: number): string => {
    const statusDescriptions: { [key: string]: string } = {
        '-4': 'Остановлено (Мировое Соглашение)',
        '-3': 'Проиграно',
        '-2': 'Отклонено',
        '-1': 'Отменено',
        '0': 'Создано',
        '1': 'Взято в работу',
        '2': 'Материалы Собраны',
        '3': 'Иск Подан',
        '4': 'Расследование Завершено',
        '5': 'Суд Назначен',
        '6': 'Выиграно',
    };

    return statusDescriptions[status.toString()] || 'Неизвестный статус';
};

export const getCourtName = (type: string): string => {
    const courtNames: { [key: string]: string } = {
        "SC": "Верховный Суд",
        "DC": "Окружной Суд",
        "AC": "Апелляционный Суд",
        "CA": "Верховный Суд",
        "CO": "Верховный Суд",
    };

    return courtNames[type.toString()] || 'Неизвестная Инстанция';
}

export const getSideLabel = (side: number): string => {
    const sideNames: { [key: string]: string } = {
        0: "Защита",
        1: "Обвинение",

    };

    return sideNames[side] || 'Неизвестная Сторона';
}


export function extractRespondent(header: string): string | null {
    const regex = /Ответчик\(-и\):\[COLOR=rgb\(44, 130, 201\)](.+?)\[\/COLOR]/;
    const match = header.match(regex);
    return match ? match[1] : null;
}

export const getStatusLabel = (status: number): string => {
    const statusDescriptions: { [key: string]: string } = {
        '-4': 'Мировое Соглашение',
        '-3': 'Проиграно',
        '-2': 'Отклонено',
        '-1': 'Отменено',
        '0': 'Создано',
        '1': 'Взято в работу',
        '2': 'Материалы Собраны',
        '3': 'Иск Подан',
        '4': 'Расследование Завершено',
        '5': 'Суд Назначен',
        '6': 'Выиграно',
    };

    return statusDescriptions[status.toString()] || 'Неизвестный статус';
};