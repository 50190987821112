import config from '../Config';
import { ClaimShort } from '../Models/Claim';

export const getAPIClaims = async (): Promise<ClaimShort[]> => {
    const response = await fetch(`${config.api_server}/api/claims`, {
        credentials: 'include',
        method: 'GET',
    });

    if (!response.ok) {
        if (response.status === 403 || response.status === 401) {
            throw new Error('Unauthorized');
        }
        throw new Error(`Error: ${response.statusText}`);
    }

    return response.json();
};

export const bookmarkClaim = (id: bigint) => {
    const bookmarkedClaims = localStorage.getItem("bookmarkedClaims")
    localStorage.setItem("bookmarkedClaims", `${bookmarkedClaims ?? ''}${id},`)
}

export const unBookmarkClaim = (id: bigint) => {
    const bookmarkedClaims = localStorage.getItem("bookmarkedClaims")
    localStorage.setItem("bookmarkedClaims", `${bookmarkedClaims?.replace(`${id},`, '') ?? ''}`)
}

export const getBookmarkedClaims = (): bigint[] => {
    const bookmarkedClaims = localStorage.getItem("bookmarkedClaims");
    if (bookmarkedClaims == null) {
        return [];
    }
    return bookmarkedClaims.split(',').map(element => BigInt(element));
}

export const isBookmarked = (id: bigint): boolean => {
    const bookmarkedClaims = getBookmarkedClaims()
    return bookmarkedClaims.includes(BigInt(id))
}