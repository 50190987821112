import { MaterialSymbol } from "react-material-symbols"
import { IconButton } from "../../../Components/Buttons"
import { green } from "../../../Services/Colors"
import { useState } from "react"
import { getReceiptsSettings } from "../../../Domains/Settings"

interface ReceiptsSettingsProps {
    saveSettings: (new_settings: {'theme': 'light' | 'dark', 'statistics': boolean, 'page-limit': number}) => void
}

const ReceiptsSettings: React.FC<ReceiptsSettingsProps> = ({ saveSettings }) => {
    const [settings, setSettings] = useState<any>(getReceiptsSettings())

    return (<>
        <div className="settings form form-container">
        <div className="settings form-item">
            <label>Тема интерфейса</label>
            <select name="theme" id="receipt-settings-theme" value={settings.theme} onChange={(e) => setSettings({...settings, 'theme': e.target.value})}>
                <option value={'light'}>Светлая</option>
                <option value={'dark'}>Темная</option>
            </select>
        </div>
        <div className="settings form-item">
            <label>Инфографика по умолчанию</label>
            <select
                name="statistics"
                id="receipt-settings-statistics"
                value={settings.statistics ? 'true' : 'false'}
                onChange={(e) => setSettings({ ...settings, 'statistics': e.target.value === 'true' })}
            >
                <option value="false">Скрыть</option>
                <option value="true">Показать</option>
            </select>
        </div>
        <div className="settings form-item">
            <label>Количество чеков на странице</label>
            <input type="number" placeholder="20" name="page-limit" id="receipt-settings-page-limit" value={settings["page-limit"]} onChange={(e) => setSettings({...settings, 'page-limit': parseInt(e.target.value)})}></input>
        </div>
        <IconButton action={() => saveSettings(settings)} label="Подтвердить" color={green}>
            <MaterialSymbol icon="save" size={22}></MaterialSymbol>
        </IconButton>
        </div>
    </>)
}
export default ReceiptsSettings