import React, { useEffect, useState } from 'react';
import './Home.css';
import { MaterialSymbol, MaterialSymbolProps } from 'react-material-symbols';
import { Dashboard } from './Home/Dashboard';
import { Profile } from './Home/Profile';
import { Hearings } from './Home/Hearings';
import { Claims } from './Home/Claims';
import { Finances } from './Home/Finances';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser } from '../Domains/Person';
import { Profile as _profile, getAPIProfile } from '../Domains/Profile';
import Loader from '../Components/Loader';
import ClaimDetails from './Claim/ClaimDetails';

const Home: React.FC = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const queryPage = searchParams.get('page');

    if (queryPage) {
      const parsedPage = parseInt(queryPage);
      if (!isNaN(parsedPage) && parsedPage >= 0) {
        setPage(parsedPage);
      }
      navigate('/');
    } else {
      setPage(0);
    }
  }, []);

  const renderContent = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const queryId = searchParams.get('id');

    if (queryId) {
      navigate('/');
      return <ClaimDetails claim_id={BigInt(queryId)} back={() => setPage(3)} />;
    }

    if (page > 1000) {
      return <ClaimDetails claim_id={BigInt(page - 1000)} back={() => setPage(3)} />;
    }

    switch (page) {
      case 0: return <Dashboard />;
      case 1: return <Profile />;
      case 2: return <Hearings />;
      case 3: return <Claims openClaim={(id: number) => setPage(1000 + id)}/>;
      case 4: return <Finances />;
      default: return <p>Эта страница не доступна.</p>;
    }
  };

  return (
    <div className='hp-page'>
      <div className='hp-container'>
        <HomepageNavigation current={page} onChange={setPage} />
        <div className='hp-display'>
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

interface HomepageNavigationProps {
  current: number;
  onChange: (page: number) => void;
}

const HomepageNavigation: React.FC<HomepageNavigationProps> = ({ current, onChange }) => {
  const navigationOptions: { icon: MaterialSymbolProps['icon'], label: string, page: number }[] = [
    { icon: 'monitoring', label: 'Главная', page: 0 },
    { icon: 'manage_accounts', label: 'Профиль', page: 1 },
    { icon: 'calendar_month', label: 'Суды', page: 2 },
    { icon: 'cases', label: 'Дела', page: 3 },
    { icon: 'payments', label: 'Финансы', page: 4 }
  ];
  if (current > 1000) {
    navigationOptions.push({ icon: 'work', label: 'Дело', page: current })
  }

  return (
    <div className='hp-navigation'>
      <HomepageHeader />
      <div className='hp-options'>
        {navigationOptions.map(({ icon, label, page }) => (
          <HomepageNavigationOption
            key={page}
            icon={icon}
            label={label}
            active={current === page}
            onClick={() => onChange(page)}
          />
        ))}
      </div>
      <div className='hp-footer'>
        <HomepageLogout />
      </div>
    </div>
  );
};

interface HomepageNavigationOptionProps {
  icon: MaterialSymbolProps['icon'];
  label: string;
  onClick: () => void;
  active: boolean;
}

const HomepageNavigationOption: React.FC<HomepageNavigationOptionProps> = ({ icon, label, onClick, active }) => (
  <div className={`hp-option ${active ? 'active' : ''}`} onClick={onClick}>
    <MaterialSymbol icon={icon} size={26} />
    <div>{label}</div>
  </div>
);

const HomepageHeader: React.FC = () => (
  <div className='hp-header'>
    <HomepageBar />
  </div>
);

const HomepageBar: React.FC = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<_profile>();

  useEffect(() => {
    const currentUser = getCurrentUser();
  
    if (currentUser) {
      const fetchData = async () => {
        try {
          const fetchedProfile = await getAPIProfile(currentUser.passport);
          setUser(fetchedProfile);
        } catch (error) {
          navigate(`/login?redirect=/`);
        }
      };
      fetchData();
    } else {
      navigate(`/login?redirect=/`);
    }
  }, []);


  return user ? 
    <div className='hp-bar'>
      <div className='hp-info'>
        <img src={user.discordAvatar} className='hp-avatar' />
        <div className='hp-details'>
          <span className='hp-name'>{user.name}</span>
          <div className='hp-contacts'>
            <span className='hp-phone'>{user.phone}</span>
            <span className='hp-discord'>{user.discordName}</span>
          </div>
        </div>
      </div>
    </div>
  :
    <Loader></Loader> 
}


const HomepageLogout: React.FC = () => (
  <div className='hp-logout'>
    <MaterialSymbol icon='logout' size={18} style={{ marginTop: 2 }} />
    <span>Выйти</span>
  </div>
);

export default Home;