import config from "../Config";

function verify(response: Response) {
    if (response.ok) { return }

    const errors: { [key: number]: string } = {
        401: 'Unauthorized',
        403: 'Forbidden',
        406: 'Not Acceptable',
        404: 'Not Found',
        500: 'Server Error'
    };

    const message = errors[response.status] || `Error: ${response.statusText}`;
    console.error('Error during API Request: \n', message, '\n', response);

    throw new Error(message)
}

export const get = async (url: string) => {
    const response = await fetch(`${config.api_server}${url}`, {
        credentials: 'include',
        method: 'GET',
    });

    verify(response)
    
    return response.json();
}

export const post = async (url: string, data: any) => {
    const headers = {
      'Content-Type': 'application/json'
    };
  
    const response = await fetch(`${config.api_server}${url}`, {
      credentials: 'include',
      method: 'POST',
      headers,
      body: JSON.stringify(data)
    });
  
    verify(response)
  
    return response.json();
  }