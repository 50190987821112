import React from 'react';
import { LawsuitFormStepProps } from './LawsuitForm';
import { formatClaimDate } from '../../../Services/Time';
import { getSideLabel, getStatusLabel } from '../../../Services/Convert';
import { Claim } from '../../../Models/Claim';
import { getUpdatedFields } from '../../../Services/Lawsuit';

interface LawsuitFormStep6Props extends LawsuitFormStepProps {
    original: Claim;
}
const fieldNamesInRussian: { [key: string]: string } = {
    number: 'Номер',
    type: 'Тип',
    sent: 'Публикация',
    status: 'Статус',
    side: 'Сторона',
    header: 'Стороны',
    forumLink: 'Форум',
    paymentLink: 'Пошлина',
};

export const LawsuitFormStep6: React.FC<LawsuitFormStep6Props> = ({ original, data, updateData }) => {
    const updatedFields = getUpdatedFields(original, data);
    return (
        <>
            {updatedFields.length > 0 ? (
                <table className='comparison'>
                    <thead>
                        <tr>
                            <th> </th>
                            <th className='old'>Было</th>
                            <th className='new'>Стало</th>
                        </tr>
                    </thead>
                    <tbody>
                        {updatedFields.map(({ field, oldValue, newValue }) => (
                            <tr key={field}>
                                <td><strong>{fieldNamesInRussian[field] || field}</strong></td>
                                <td className='old'>
                                {
                                    field =='sent' ? formatClaimDate(new Date(oldValue)) : field == 'status' ? getStatusLabel(oldValue) : field == 'side' ? getSideLabel(oldValue) : oldValue ?? 'N/A'
                                }
                                </td>
                                <td className='new'>
                                {
                                    field =='sent' ? formatClaimDate(new Date(newValue)) : field == 'status' ? getStatusLabel(newValue) : field == 'side' ? getSideLabel(newValue) : newValue
                                }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>Изменения не обнаружены.</p>
            )}
        </>
    )
};