import React, { useState } from 'react';
import { ClaimNew } from '../../../Models/Claim';
import './CreateClaimForm.css';
import { postAPIclaim } from '../../../Domains/Claims';

interface CreateClaimFormProps {
    handleSubmit?: (formState: ClaimNew) => void;
    handleClose: () => void;
}

const CreateClaimForm: React.FC<CreateClaimFormProps> = ({ handleSubmit, handleClose }) => {
    const [formState, setFormState] = useState({
        description: '',
        happened: '',
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        setFormState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        let happened = new Date(formState.happened + "Z");
        if (handleSubmit) {
            handleSubmit({
                description: formState.description,
                happened: happened
            });
        } else {
            postAPIclaim({ description: formState.description, happened: happened })
        }
        handleClose();
        setFormState({
            description: '',
            happened: '',
        })
    };

    return (
        <form className="create-claim-form" onSubmit={handleFormSubmit}>

            <label>Краткое Описание</label>
            <input type="text" name="description" value={formState.description} onChange={handleChange} required />

            <label>Дата произошедшего</label>
            <input type="datetime-local" name="happened" value={formState.happened} onChange={handleChange} min={"2024-01-01T00:00"} max={"2025-01-01T00:00"} required />
            
            <button type="submit">Подтвердить</button>
        </form>
    );
};

export default CreateClaimForm;