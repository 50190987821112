import React, { useState } from 'react';
import { LawsuitFormStepProps } from './LawsuitForm';
import { IconButton } from '../../../Components/Buttons';
import { MaterialSymbol } from 'react-material-symbols';
import { red, green } from '../../../Services/Colors';

export const demandOptions = ['Компенсация', 'Судимость', 'Лицензия', 'Должность'];

export const LawsuitFormStep3: React.FC<LawsuitFormStepProps> = ({ data, updateData }) => {
    const [selectedDemand, setSelectedDemand] = useState<string>();

    return (
        <>
        <label>Требования</label>
        <div id="demands-label" className='buttoned-label'>
            
            <select id="demand" name="demand" onChange={(e) => {setSelectedDemand(e.target.value)}} >
            <option value={undefined}>Не выбрано</option>
            {demandOptions.map((option, index) => (
                <option key={index} value={option}>{option}</option>
            ))}
            </select>
            <IconButton action={ () => { if (selectedDemand !== undefined) { updateData({demands: [...data.demands, selectedDemand ]}) } } } color={green} small={true}>
                <MaterialSymbol icon="add" color="white" size={22} />
            </IconButton>
        </div>
        <div id="demands" className='buttoned-data'>
            {data.demands?.map((name, index) => (
            <div key={index} className="demand buttoned-item">
                <input
                    type="text"
                    name="name"
                    value={name}
                    disabled
                />
                <IconButton action={() => {
                    const newDemands = [...data.demands];
                    newDemands.splice(index, 1);
                    updateData({demands: newDemands});
                }}
                color={red}
                small={true}
                >
                    <MaterialSymbol icon="remove" color="white" size={22} />
                </IconButton>
            </div>
            ))}
        </div>
        </>
    );
};