import React from 'react';

import { MaterialSymbol } from 'react-material-symbols';


interface IconDefault {
    onClick?: () => void;
    size?: number;
}

export const CloseIcon: React.FC<IconDefault> = ({ onClick }) => {
    return (
        <div onClick={onClick} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <MaterialSymbol icon="close" size={32} color='black' />
        </div>
    );
};
interface BookmarkIconProps extends IconDefault {
    fill: boolean;
}
export const BookmarkIcon: React.FC<BookmarkIconProps> = ({ onClick, fill }) => {
    return (
        <div onClick={onClick} style={{ cursor: 'pointer' }}>
        {fill ? (
            <MaterialSymbol icon="hotel_class" size={32} color='#FFD700' fill />
        ) : (
            <MaterialSymbol icon="hotel_class" size={32} color='black' />
        )}
        </div>
    );
};
interface ClaimForumLinkIconProps extends IconDefault {
    active: boolean;
}
export const ClaimForumLinkIcon: React.FC<ClaimForumLinkIconProps> = ({ onClick, active }) => {
    return (
        <div onClick={onClick} style={{ cursor: 'pointer' }}>
        {active ? (
            <MaterialSymbol icon="link" size={32} color='#00308F' />
        ) : (
            <MaterialSymbol icon="add_link" size={32} color='#C40234' />
        )}
        </div>
    );
};

export const EditIcon: React.FC<IconDefault> = ({ onClick, size }) => {
    return (
        <div onClick={onClick ?? undefined} style={{ cursor: 'pointer' }}>
            <MaterialSymbol icon="edit" size={size ?? 24} color='black' />
        </div>
    );
};

export const ClaimStatusChangeIcon: React.FC<IconDefault> = ({ onClick }) => {
    return (
        <div onClick={onClick} style={{ cursor: 'pointer' }}>
            <MaterialSymbol icon="swap_horiz" size={24} color='black' />
        </div>
    );
};

export const DeletePersonIcon: React.FC<IconDefault> = ({ onClick }) => {
    return (
        <div onClick={onClick} style={{ cursor: 'pointer' }}>
            <MaterialSymbol icon="person_remove" size={24} color='#BB271A' />
        </div>
    );
};

export const DeleteDocumentIcon: React.FC<IconDefault> = ({ onClick }) => {
    return (
        <div onClick={onClick} style={{ cursor: 'pointer' }}>
            <MaterialSymbol icon="scan_delete" size={24} color='#BB271A' />
        </div>
    );
};

export const CreateLegalDocument: React.FC<IconDefault> = ({ onClick }) => {
    return (
        <div onClick={onClick} style={{ cursor: 'pointer' }}>
            <MaterialSymbol icon="gavel" size={32} color='#0063d5' />
        </div>
    );
};

export const CreateLegalMotion: React.FC<IconDefault> = ({ onClick }) => {
    return (
        <div onClick={onClick} style={{ cursor: 'pointer' }}>
            <MaterialSymbol icon="note_stack" size={32} color='#0063d5' />
        </div>
    );
};

interface StarIconProps extends IconDefault {
    fill: boolean;
}
export const StarIcon: React.FC<StarIconProps> = ({ onClick, fill }) => {
    return (
        <div onClick={onClick} style={{ cursor: 'pointer' }}>
        {fill ? (
            <MaterialSymbol icon="star" size={32} color='#FFD700' fill />
        ) : (
            <MaterialSymbol icon="star" size={32} color='black' />
        )}
        </div>
    );
};