export const getReceiptsSettings = (): any  => {
    const data = localStorage.getItem('receipts_settings')

    if (data) {
        const json = JSON.parse( data || '{}');
        return json
    } else {
        const json = {'theme': 'white', 'statistics': false, 'page-limit': 20}
        localStorage.setItem('receipts_settings', JSON.stringify(json));
        return json
    }

}
export const setReceiptsSettings = (settings: {'theme': 'light' | 'dark', 'statistics': boolean, 'page-limit': number})  => {
    localStorage.setItem('receipts_settings', JSON.stringify(settings));
}