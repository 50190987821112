import Cookies from 'universal-cookie';
import { Claim } from '../Models/Claim';
import { ClaimAdvocate } from '../Models/Advocate';

export const isLoginExpired = (): boolean => {
    const lastLogin = new Cookies().get('last-login');
    if (!lastLogin) return true;
  
    const lastLoginTime = new Date(parseInt(lastLogin, 10));
    const currentTime = new Date();
    const timeDifference = currentTime.getTime() - lastLoginTime.getTime();
    
    // 600000 = 10 minutes
    // 3600000 = 1 hour
    // 43200000 = 12 hours
    // 86400000 = 24 hours
    return timeDifference > 600000;
};
export const getCurrentUserFromClaim = (claim: Claim): ClaimAdvocate | undefined => {
    const cookie = localStorage.getItem('user')
    const user = JSON.parse( cookie || '{}');
    return claim.advocates.find(advocate => advocate.passport === user.passport);
}