import React, { ReactNode, useEffect, useState } from "react";
import { MaterialSymbol } from "react-material-symbols"
import { addAPIEvidence, claimAPIAdvocate, claimAPIClient, editAPIAdvocate, editAPIClaim, editAPIClient, editAPIEvidence, getAPIClaim } from "../../Services/Claim";
import Loader from "../../Components/Loader";
import { getStatusLabel } from "../../Services/Convert";
import './ClaimDetails.css'
import { formatClaimDate, getCurrentMoscowTime, timeBetweenDatesLabel, timeSinceMoscowLabel } from "../../Services/Time";
import { bookmarkClaim, isBookmarked, unBookmarkClaim } from "../../Services/Claims";
import { Claim } from "../../Models/Claim";
import { PopUp } from "../../Components/PupUp";
import { IconButton } from "../../Components/Buttons";
import { ClaimDetailsContentArea } from "../../Components/ContentArea";
import { ClaimDetailsPerson } from "../../Components/Person";
import DateForm from "./ClaimForms/ClaimFormsDate";
import { DescriptionForm, ForumLinkForm, HeaderForm, StatusForm } from "./ClaimForms/ClaimFormsOther";
import { ClaimDetailsEvidence, ClaimEvidenceForm } from "./ClaimForms/ClaimFormsEvidence";
import { ClaimEvidence } from "../../Models/Evidence";
import { ClaimClient } from "../../Models/Client";
import { ClaimAdvocate } from "../../Models/Advocate";
import { ClaimAdvocateAddForm, ClaimAdvocateEditForm } from "./ClaimForms/ClaimFormsAdvocate";
import { ClaimClientAddForm, ClaimClientEditForm } from "./ClaimForms/ClaimFormsClient";
import ClaimInfoForm from "./ClaimForms/ClaimFormsInfo";
import LawsuitForm from "./ClaimLawsuit/LawsuitForm";
import { MotionForm } from "./ClaimMotion/MotionForms";


interface ClaimDetailsProps {
    claim_id?: bigint;
    back?: () => void;
}

const ClaimDetails: React.FC<ClaimDetailsProps> = ({claim_id, back}) => {
    const [status, setStatus] = useState<number>(0);
    const [claim, setClaim] = useState<Claim | null>(null);

    const [popupTitle, setPopupTitle] = useState<string>("");
    const [popupContent, setPopupContent] = useState<React.ReactNode | null>(null);
    const [isPopUpOpen, setIsPopUpOpen] = useState<boolean>(false);
    
    const [isBookmark, setIsBookmark] = useState<boolean>(false);

    useEffect(() => {
        if (claim_id) {
            setStatus(0)
            const fetchData = async () => {
                const fetchedClaim = await getAPIClaim(BigInt(claim_id));
                setClaim(fetchedClaim);
                setStatus(1)
            };
            fetchData();
        }
    }, [claim_id]);

    const openPopUp = (title: string, content: ReactNode) => {
        setPopupTitle(title)
        setPopupContent(content)
        setIsPopUpOpen(true)
    }
    const closePopUp = () => {
        setPopupTitle('')
        setPopupContent(null)
        setIsPopUpOpen(false)
    }


    useEffect(() => {
        if (claim){
          setIsBookmark(isBookmarked(claim.id))
        } else {
          setIsBookmark(false)
        }
    }, [claim])


    function reload() {
        const url = new URL(window.location.href);
        if (claim_id){
          url.searchParams.set("id", claim_id.toString());
        }
        window.location.href = url.toString();
    }

    if (claim && status == 1) {
        const add_evidence = () => {
            openPopUp(`Новая Улика`, <ClaimEvidenceForm submit={(updated) => {
                addAPIEvidence(claim.id, updated);
                closePopUp()
                reload()
            }} close={closePopUp} />)
        }
        const add_advocate = () => {
            openPopUp("Добавление Клиента", <ClaimAdvocateAddForm submit={(advocate_id) => {
                claimAPIAdvocate(claim.id, advocate_id);
                closePopUp()
                reload()
            }} close={closePopUp} existingAdvocatesIds={claim.advocates.map((advocate) => advocate.passport)} />)
        }
        const add_client = () => {
            openPopUp("Добавление Клиента", <ClaimClientAddForm submit={(client_id) => {
                claimAPIClient(claim.id, client_id);
                closePopUp()
                reload()
            }} close={closePopUp} existingClientIds={claim.clients.map((client) => client.passport)} />)
        }

        const edit_evidence = (evidence: ClaimEvidence) => {
            openPopUp(`Улика №${evidence.id}`, <ClaimEvidenceForm currentEvidence={evidence} submit={(updated) => {
                editAPIEvidence(evidence.id, updated);
                closePopUp()
                reload()
            }} close={closePopUp} />)
        }
        const edit_client = (client: ClaimClient) => {
            openPopUp(`${client.name} (${client.passport})`, <ClaimClientEditForm client={client} submit={(updated) => {
                editAPIClient(client.passport, updated);
                closePopUp()
                reload()
            }} remove={ () => {
                claimAPIClient(claim.id, client.passport, true);
                closePopUp()
                reload()
            }} close={closePopUp} />)
        }
        const edit_advocate = (advocate: ClaimAdvocate) => {
            openPopUp(`${advocate.name} (${advocate.passport})`, <ClaimAdvocateEditForm advocate={advocate} submit={(updated) => {
                editAPIAdvocate(advocate.passport, updated);
                closePopUp()
            }} remove={ () => {
                claimAPIAdvocate(claim.id, advocate.passport, true);
                closePopUp()
                reload()
            }} close={closePopUp} />)
        }
    
        const edit_happened = () => {
            openPopUp('Дата происшествия', <DateForm currentDate={claim.happened} 
                submit={(date: Date) => {
                    setClaim((prevClaim) => prevClaim ? { ...prevClaim, happened: new Date(new Date(date.getTime() + date.getTimezoneOffset() * 60000).toISOString().slice(0, 16) + "Z") } : null);
                    editAPIClaim(claim.id, {happened: date})}
            } close={closePopUp} />)
        }
        const edit_sent = () => {
            openPopUp('Дата подачи заявления', <DateForm currentDate={claim.sent} 
                submit={(date: Date) => {
                    setClaim((prevClaim) => prevClaim ? { ...prevClaim, sent: new Date(new Date(date.getTime() + date.getTimezoneOffset() * 60000).toISOString().slice(0, 16) + "Z") } : null);
                    editAPIClaim(claim.id, {sent: date})}
                } close={closePopUp} />)
        }
        const edit_hearing = () => {
            openPopUp('Дата судебного заседания', <DateForm currentDate={claim.hearing} 
                submit={(date: Date) => {
                    setClaim((prevClaim) => prevClaim ? { ...prevClaim, hearing: new Date(new Date(date.getTime() + date.getTimezoneOffset() * 60000).toISOString().slice(0, 16) + "Z") } : null);
                    editAPIClaim(claim.id, {hearing: date})}
            } close={closePopUp} />)
        }

        const edit_status = () => {
            openPopUp("Статус дела", <StatusForm currentStatus={claim.status} submit={(status) => {
                setClaim((prevClaim) => prevClaim ? { ...prevClaim, status: status } : null);
                editAPIClaim(claim.id, {status: status})
            }} close={closePopUp} />)
        }
        const edit_info = () => {
            openPopUp("Основная Информация", <ClaimInfoForm claim={claim} submit={(updated) => {
                setClaim((prevClaim) => prevClaim ? { ...prevClaim, ...updated } : null);
                editAPIClaim(claim.id, updated)
            }} close={closePopUp} />)
        }
        const edit_description = () => {
            openPopUp("Описание", <DescriptionForm currentDescription={claim.description} submit={(description) => {
                setClaim((prevClaim) => prevClaim ? { ...prevClaim, description: description } : null);
                editAPIClaim(claim.id, {description: description})
            }} close={closePopUp} />)
        }
        const edit_header = () => {
            openPopUp("Участники", <HeaderForm currentHeader={claim.header ?? ''} submit={(header: string | undefined) => {
                setClaim((prevClaim) => prevClaim ? { ...prevClaim, header: header } : null);
                editAPIClaim(claim.id, {header: header})
            }} close={closePopUp} />)
        }
    
        const add_bookmark = () => {
            bookmarkClaim(claim.id)
            setIsBookmark(true)
        }
        const remove_bookmark = () => {
            unBookmarkClaim(claim.id)
            setIsBookmark(false)
        }

        const add_forumLink = () => {
            openPopUp("Ссылка на Форум", <ForumLinkForm submit={(forumLink: string) => {
                setClaim((prevClaim) => prevClaim ? { ...prevClaim, forumLink: forumLink } : null);
                editAPIClaim(claim.id, {forumLink: forumLink})
            }} close={closePopUp} />)
        }

        const create_motion = () => {
            openPopUp('Формирование Ходатайства', <MotionForm claim={claim} close={() => {setIsPopUpOpen(false); setPopupContent(<></>); reload();}}></MotionForm>)
        }
        const create_lawsuit = () => {
            openPopUp('Формирование Иска', <LawsuitForm claim={claim} close={() => {setIsPopUpOpen(false); setPopupContent(<></>); reload();}} />)
        }

        const claimLabel = `(${claim.id.toString()}) ${claim.type !== '‒' ? `${claim.type}-${claim.number} ${claim.side == 0 ? 'Защита' : 'Обвинение'}` : `${claim.number}`} `
        
        return (
        <div className="claim-details">
            <div className="claim-details-header">
                <div className="label">{claimLabel}</div>
                <div className="status">
                    <div className="status-label">{getStatusLabel(claim.status)}</div>
                    <div className="status-action hover-effect" onClick={edit_status}><MaterialSymbol icon="update" size={24} /></div>
                </div>
            </div>
            <div className="claim-details-actions">
                <IconButton action={() => back ? back() : undefined} color="#9300ff" label="Вернуться">
                    <MaterialSymbol icon="keyboard_return" color="white" size={22} />
                </IconButton>
                { claim.forumLink ? 
                    <IconButton action={() => {window.open(claim.forumLink, '_blank' );}} color="#9300ff">
                        <MaterialSymbol icon="link" color="white" size={22} />
                    </IconButton>
                : 
                    <IconButton action={add_forumLink} color="#ff0000">
                        <MaterialSymbol icon="add_link" color="white" size={22} />
                    </IconButton>
                }
                { isBookmark ?                 
                    <IconButton action={remove_bookmark}>
                        <MaterialSymbol icon="star" color="yellow" fill={true} size={22}/>
                    </IconButton>
                :
                    <IconButton action={add_bookmark}>
                        <MaterialSymbol icon="star" color="white" fill={true} size={22}/>
                    </IconButton>
                }
                <IconButton label="Ходатайство" action={create_motion} color="#2ba430">
                    <MaterialSymbol icon="new_window" color="white"  size={22}/>
                </IconButton>
                <IconButton label="Иск" action={create_lawsuit} color="#2ba430">
                    <MaterialSymbol icon="gavel" color="white" size={22}/>
                </IconButton>
                <IconButton label="Редактировать" action={edit_info}>
                    <MaterialSymbol icon="edit_document" color="white" size={22}/>
                </IconButton>
            </div>
            <div className="claim-details-content">
                <div className="horizontal-f">
                    <div className="description-area">
                        <ClaimDetailsContentArea title="Описание" icon_title="description" icon_action="edit_note" action={edit_description}>
                            {claim.description ? claim.description.split('\n').map((line, index) => <p key={index} className={`description-p ${index === 0 ? 'bold': ''}`}>{line}</p>) : <p className="description-p">'N/A'</p>}
                        </ClaimDetailsContentArea>
                    </div>
                    <div className="participants-area">
                        <ClaimDetailsContentArea title="Участники" icon_title="groups" icon_action="edit_note" action={edit_header}>
                            {claim.header ? claim.header.split('\n').map((line, index) => <p key={index} className="header-p">{line}</p>) : <p className="header-p">N/A</p>}
                        </ClaimDetailsContentArea>
                    </div>
                </div>
                <div className="horizontal-s">
                    <ClaimDetailsContentArea title="Инцидент" icon_title="event_upcoming" icon_action="edit_calendar" action={edit_happened}>
                        <p className="date-p">{formatClaimDate(claim.happened ? new Date(claim.happened) : null)}</p>
                        <p className="date-p">С момента инцидента прошло: <b>{ claim.happened ? timeSinceMoscowLabel(new Date(claim.happened)) : 'N/A'}</b> </p>
                    </ClaimDetailsContentArea>
                    <ClaimDetailsContentArea title="Заявление" icon_title="event_available" icon_action="edit_calendar" action={edit_sent}>
                        <p className="date-p">{formatClaimDate(claim.sent ? new Date(claim.sent) : null )}</p>
                        <p className="date-p">С момента подачи прошло: <b>{ claim.sent ? timeSinceMoscowLabel(new Date(claim.sent)) : 'N/A'}</b> </p>
                    </ClaimDetailsContentArea>
                    <ClaimDetailsContentArea title="Заседание" icon_title="event" icon_action="edit_calendar" action={edit_hearing}>
                        <p className="date-p">{formatClaimDate(claim.hearing ? new Date(claim.hearing) : null )}</p>
                        <p className="date-p">С момента инцидента до заседания прошло: <b>{ claim.happened && claim.hearing ? timeBetweenDatesLabel(new Date(claim.happened), new Date(claim.hearing)) : 'N/A'}</b> </p>
                        <p className="date-p">До заседания осталось: <b>{ claim.hearing ? timeBetweenDatesLabel(getCurrentMoscowTime(), new Date(claim.hearing)) : 'N/A' }</b> </p>
                    </ClaimDetailsContentArea>
                </div>
                <div className="horizontal-f">
                    <ClaimDetailsContentArea title="Клиенты" icon_title="face" icon_action="person_add" action={add_client}>
                        { claim.clients.map((person, index) => 
                            <ClaimDetailsPerson key={index} passport={person.passport} name={person.name} phone={person.phone ?? 'N/A'} discord={person.discordName ?? 'N/A'} action={() => edit_client(person)} />
                        )}
                    </ClaimDetailsContentArea>
                    <ClaimDetailsContentArea title="Адвокаты" icon_title="badge" icon_action="person_add" action={add_advocate}>
                        { claim.advocates.map((person, index) => 
                            <ClaimDetailsPerson key={index} passport={person.passport} name={person.name} phone={person.phone ?? 'N/A'} discord={person.discordName ?? 'N/A'} action={() => edit_advocate(person)} />
                        )}
                    </ClaimDetailsContentArea>
                </div>
                <div className="horizontal-f">
                    <ClaimDetailsContentArea title="Доказательства" icon_title="contract" icon_action="docs_add_on" action={add_evidence}>
                        { claim.evidences.map((evidence, index) => 
                            <ClaimDetailsEvidence key={index} name={evidence.name} link={evidence.link} obtaining={evidence.obtaining} action={() => edit_evidence(evidence)} />
                        )}
                    </ClaimDetailsContentArea>
                </div>
            </div>

            <PopUp title={ popupTitle } show={isPopUpOpen} close={() => {setIsPopUpOpen(false); setPopupContent(<></>)}}>
                { popupContent }
            </PopUp>
        </div>
        )
    }
    return <Loader></Loader>
}

export default ClaimDetails