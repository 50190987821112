import config from "../Config";
import { ClaimAdvocate } from "../Models/Advocate";
import { Claim } from "../Models/Claim";
import { ClaimClient } from "../Models/Client";
import { ClaimEvidence } from "../Models/Evidence";

export interface Lawsuit {
    claim: Claim;
    number: number;
    type: string;
    forumLink?: string;
    paymentLink?: string;
    advocate?: ClaimAdvocate;
    client?: ClaimClient;
    defendants: {name: string, articles: string}[];
    evidences: ClaimEvidence[];
    demands: string[];
    warnings: Warning[];
}
export interface Warning {
    type: string
    message: string
    checked?: boolean
}

function getCourtName(type: string): "Окружной Суд" | "Верховный Суд" | undefined {
    const courtNames: { [key: string]: "Окружной Суд" | "Верховный Суд" } = {
      "SC": "Верховный Суд",
      "DC": "Окружной Суд",
    };
  
    return courtNames[type.toString()] || undefined;
  }
  
function getCompensation(type: string): string {
    const courtNames: { [key: string]: string } = {
        "SC": "100.000$",
        "DC": "40.000$",
    };
    
    return courtNames[type.toString()] || "00.000$";
}

function getPayment(type: string): string {
    const courtNames: { [key: string]: string } = {
        "SC": "15.000$",
        "DC": "5.000$",
    };
    
    return courtNames[type.toString()] || "00.000$";
}

function getDemandText(lawsuit: Lawsuit, demand: string): string {
    const demandNames: { [key: string]: string } = {
        'Компенсация': `Выплатить ${lawsuit.client?.name} материальную компенсацию в размере 00.000$ за ПРИЧИНА`, 
        'Судимость': `Снять ${lawsuit.client?.name} судимость полученную в результате ареста 00:00 00.00.2024`, 
        'Лицензия': `Восстановить ${lawsuit.client?.name} лицензию на ВИД ЛИЦЕНЗИИ`, 
        'Должность': `Восстановить ${lawsuit.client?.name} в ОРГАНИЗАЦИЯ на должность ДОЛЖНОСТЬ`
    };
    
    return demandNames[demand] || "???";

}

export function generateLawsuit(lawsuit: Lawsuit): { string: string, header: string } {
    const formattedDate = (date: Date) => date.toLocaleDateString('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  
    const dem_defendants = lawsuit.defendants.map((defendant) => `Признать ${defendant.name} виновным в совершении преступления предусмотренного статьями ${defendant.articles} Уголовно-Административного кодекса штата Сан-Андреас и привлечь к ответственности назначив справедливое наказание`)
    const dem_default = [`Выплатить ${lawsuit.client?.name} моральную компенсацию в размере 50.000$`, `Выплатить ${lawsuit.client?.name} компенсацию судебных издержек в размере ${getCompensation(lawsuit.type)} за оплату услуг адвоката`, `Выплатить стороне обвинения компенсацию судебных издержек в размере ${getPayment(lawsuit.type)} за оплату государственной пошлины при подачи искового заявления`]
    const dem_additional = lawsuit.demands.map((demand) => getDemandText(lawsuit, demand))
    const final_dem = [...dem_defendants, ...dem_default, ...dem_additional].map((content, index) => `${index + 1}. ${content}`).join(';\n')

    const evidences = lawsuit.evidences?.map((evidence) => `[URL='${evidence.link}'][COLOR=rgb(65, 105, 225)][${evidence.name}][/COLOR][/URL]${evidence.obtaining ? ` (( [URL='${evidence.obtaining}']Получение[/URL] ))` : '' }`).join(', ');
    const defendants = lawsuit.defendants?.map((defendant) => `${defendant.name}`).join(', ')
    
    const to = `[RIGHT]В ${getCourtName(lawsuit.type)} Штата Сан-Андреас\n90001, г. Лос-Сантос, Рокфорд-хиллз, Рокфорд-драйв\n`
    const header = `Истец: ${lawsuit.client?.name}\nПредставитель истца: ${lawsuit.advocate?.name}\nОтветчик(-и): ${defendants}`
    const heading = `[/RIGHT] \n\n[CENTER][B][SIZE=5]Исковое заявление [COLOR=rgb(65, 168, 95)]№${lawsuit.number}[/COLOR][/SIZE][/B][/CENTER] \n`
    const beginning = `Я, партнер частного адвокатского бюро "MBA Legal Group", [COLOR=rgb(65, 105, 225)]${lawsuit.advocate?.name}[/COLOR], являясь представителем [COLOR=rgb(65, 105, 225)]${lawsuit.client?.name}[/COLOR] подаю исковое заявление в ${getCourtName(lawsuit.type)} в отношении ответчика(-ов) [COLOR=rgb(65, 105, 225)]${defendants}[/COLOR], объясняя всю ситуацию, а также прилагая все необходимые суду доказательства. \n`
    const description = `\n${lawsuit.claim.description.split('\n').slice(1).join('\n \n')} \n`
    const demands = `\n[B]Исковые требования:[/B] \n ${final_dem} \n`
    const attachments = `\n[B]Перечень прилагаемых документов:[/B] \n`
    const at_client_id = `1. Паспорт истца: [URL='${lawsuit.client?.passportLink}'][COLOR=${lawsuit.client?.passportLink ? "rgb(65, 105, 225)" : 'rgb(226, 80, 65)'}][Гиперссылка][/COLOR][/URL] \n`
    const at_advocate_id = `2. Паспорт адвоката: [URL='${lawsuit.advocate?.passportLink}'][COLOR=${lawsuit.advocate?.passportLink ? "rgb(65, 105, 225)" : 'rgb(226, 80, 65)'}][Гиперссылка][/COLOR][/URL] \n`
    const at_advocate_license = `3. Лицензия адвоката: [URL='${lawsuit.advocate?.licenseLink}'][COLOR=${lawsuit.advocate?.licenseLink ? "rgb(65, 105, 225)" : 'rgb(226, 80, 65)'}][Гиперссылка][/COLOR][/URL] \n`
    const at_agreement = `4. Соглашение: [URL='${lawsuit.client?.agreementLink}'][COLOR=${lawsuit.client?.agreementLink ? "rgb(65, 105, 225)" : 'rgb(226, 80, 65)'}][Гиперссылка][/COLOR][/URL] \n`
    const at_evdences = `5. Доказательства: ${evidences}\n`
    const at_witnesses = `6. Свидетели: [COLOR=rgb(65, 105, 225)] --- [/COLOR] \n`
    const at_advocate_contact = `7. Контакты адвоката: [COLOR=rgb(65, 105, 225)]${lawsuit.advocate?.discordName ?? 'ДИСКОРД'}@sa.com ${lawsuit.advocate?.phone ?? 'ТЕЛЕФОН'}[/COLOR] \n`
    const at_client_contact =  `8. Контакты истца: [COLOR=rgb(65, 105, 225)]${lawsuit.client?.discordName ?? 'ДИСКОРД'}@sa.com ${lawsuit.client?.phone ?? 'ТЕЛЕФОН'}[/COLOR] \n`
    const at_payment = `9. Оплата пошлины: [URL='${lawsuit.paymentLink}'][COLOR=${lawsuit.paymentLink ? "rgb(226, 80, 65)" : 'rgb(226, 80, 65)'}][Перевод][/COLOR][/URL]\n`
    const footer = `[RIGHT][COLOR=rgb(65, 105, 225)]${formattedDate(new Date())} \n[IMG]${config.api_server}/source/signature/${lawsuit.advocate?.passport}[/IMG][/COLOR][/RIGHT]`
    
    const string = `${to}${header}${heading}${beginning}${description}${demands}${attachments}${at_client_id}${at_advocate_id}${at_advocate_license}${at_agreement}${at_evdences}${at_witnesses}${at_advocate_contact}${at_client_contact}${at_payment}${footer}`;
  
    return { string, header }
}
  
export const getUpdatedFields = (original: Claim, data: Lawsuit) => {
    const updatedFields: { field: string; oldValue: any; newValue: any }[] = [];

    Object.keys(original).forEach((key) => {
        if (
            key !== 'evidences' &&
            key !== 'clients' &&
            key !== 'advocates'
        ) {
            const originalValue = (original as any)[key];
            const newValue1 = (data as any)[key];
            const newValue2 = (data.claim as any)[key];
            const newValue = newValue1 == undefined ? newValue2 : newValue1
            console.log(`"${key}" : ${newValue1}, ${newValue2} --> ${originalValue} ? ${newValue}`)
            
            if (originalValue !== newValue) {
                updatedFields.push({ field: key, oldValue: originalValue, newValue: newValue });
            }
        }
    });

    return updatedFields;
};

