import { Color } from "react-bootstrap/esm/types"
import { MaterialSymbol, MaterialSymbolProps } from "react-material-symbols"
import './Headers.css'

interface SearchHeaderProps {
    icon_left?: MaterialSymbolProps['icon']
    color_left?: Color
    action_left?: () => void

    icon_right?: MaterialSymbolProps['icon']
    color_right?: Color
    action_right?: () => void

    icon_main?: MaterialSymbolProps['icon']
    color_main?: Color
    action_main?: () => void

    search?: (event: React.ChangeEvent<HTMLInputElement>) => void
    search_placeholder?: string

}
const SearchHeader: React.FC<SearchHeaderProps> = ({icon_left, icon_main, icon_right, color_left, color_main, color_right, action_left, action_main, action_right, search, search_placeholder}) => {
    return (<header>
        <hgroup className="sh-left">
            {icon_left ? 
                <SearchHeaderIcon icon={icon_left} color={color_left}  size={30} action={action_left}/> 
                :
                <div style={{ minWidth: '30px', minHeight: '30px'}}></div>
            }
        </hgroup>
        <search>
            <div className="sh-search">
                <SearchHeaderIcon icon="search" />
                <input type="text" placeholder={search_placeholder} onChange={ search }></input>
                {icon_main ?
                    <SearchHeaderIcon icon={icon_main} color={color_main} size={22} action={action_main} />
                    :
                    <div style={{ minWidth: '22px', minHeight: '22px'}}></div>

                }
            </div>
        </search>
        <hgroup className="sh-right">
            {icon_right ?
                <SearchHeaderIcon icon={icon_right} color={color_right} size={30} action={action_right}/>
                :
                <div style={{ minWidth: '30px', minHeight: '30px'}}></div>
            }
        </hgroup>
    </header>)
}
interface SearchHeaderIconProps {
    icon?: MaterialSymbolProps['icon']
    action?: () => void;
    color?: Color;
    size?: number;
    bold?: boolean
}
const SearchHeaderIcon: React.FC<SearchHeaderIconProps> = ({icon, action, color, size, bold}) => {
    return (<div onClick={action} className={`${action ? 'actionable': ''}`}> <MaterialSymbol icon={icon ?? 'error'} size={size ?? 20} color={color} style={bold ? {fontWeight: 'bold'} : {}}/> </div>)
}

export default SearchHeader