import { MaterialSymbol } from "react-material-symbols"
import Paginator from "../../Components/Paginator"

interface ReceiptsFooterProps {
    onSettings?: () => void
    onHelp?: () => void
    
    items: number
    page: number
    setPage: (page: number) => void
    perPage: number
}

const ReceiptsFooter: React.FC<ReceiptsFooterProps> = ({ onSettings, onHelp, items, page, setPage, perPage}) => {

    return (<footer>
        <div className='actionable' onClick={onHelp}><MaterialSymbol icon="help" size={22}></MaterialSymbol></div>
        <Paginator
            totalItems={items}
            itemsPerPage={perPage}
            currentPage={page}
            onPageChange={(page: number) => setPage(page)}
        />
        <div className='actionable' onClick={onSettings}><MaterialSymbol icon="settings" size={22}></MaterialSymbol></div>
    </footer>)
}
export default ReceiptsFooter