import { useEffect, useState } from "react";
import { ClaimClient, ClientShort } from "../../../Models/Client";
import { IconButton } from "../../../Components/Buttons";
import { MaterialSymbol } from "react-material-symbols";
import '../ClaimDetails.css'
import '../../../Components/Forms.css'
import { getAPIClients } from "../../../Services/Claim";
import { SmallLoader } from "../../../Components/Loader";

interface ClaimClientEditFormProps {
    client: ClaimClient;
    submit: (updated:  Partial<ClaimClient>) => void;
    close: () => void;
    remove: () => void;
};

export const ClaimClientEditForm: React.FC<ClaimClientEditFormProps> = ({ client, submit, close, remove }) => {
    const [formState, setFormState] = useState({
        phone: client.phone ?? "",
        passportLink: client.passportLink ?? "",
        agreementLink: client.agreementLink ?? "",
    });

  
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
  
    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        submit(formState);
        close();
    };
  
    return (
        <form className="form-container" onSubmit={handleFormSubmit}>
            <label htmlFor="phone">Телефон:</label>
            <input id="phone" name="phone" value={formState.phone} onChange={handleChange} />
  
            <label htmlFor="passportLink">Ссылка на Пасспорт:</label>
            <input id="passportLink" name="passportLink" value={formState.passportLink} onChange={handleChange} />
  
            <label htmlFor="agreementLink">Ссылка на Соглашение:</label>
            <input id="agreementLink" name="agreementLink" value={formState.agreementLink} onChange={handleChange} />

            <div className="button-area">
                <IconButton label="Сохранить" action={() => {submit(formState); close()}} color="#01a049">
                    <MaterialSymbol icon="save" color="white" size={22} />
                </IconButton>
                <IconButton label="Удалить" action={() => {remove(); close()}} color="#ff0000">
                    <MaterialSymbol icon="person_remove" color="white" size={22} />
                </IconButton>
            </div>
        </form>
    );
};

interface ClaimClientAddFormProps {
    submit: (client_id: number) => void;
    close: () => void;
    existingClientIds: number[];
};
export const ClaimClientAddForm: React.FC<ClaimClientAddFormProps> = ({ submit, close, existingClientIds }) => {
    const [clients, setClients] = useState<ClientShort[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState<boolean>(true);
  
    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const fetchedClients = await getAPIClients();
            setClients(fetchedClients);
            setLoading(false);
        };
        fetchData();
    }, []);
  
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };
  
  
    const filteredClients = clients.filter(client =>
        (client.name.toLowerCase().includes(searchTerm.toLowerCase()) || client.passport.toString().includes(searchTerm)) &&
        !existingClientIds.includes(client.passport)
    );
  
    if (loading) {
        return <SmallLoader />;
    }
  
    return (
        <form className="form-container" onSubmit={(e) => e.preventDefault()}>
            <label>Выберите клиента</label>
            <input
                type="text"
                placeholder="Поиск"
                value={searchTerm}
                onChange={handleSearchChange}
            />
            <div className="person-list">
                {filteredClients.map(client => (
                    <div
                        key={client.passport}
                        className="person-item"
                        onClick={() => submit(client.passport)}
                    >
                        {client.name} ({client.passport})
                    </div>
                ))}
            </div>
        </form>
    );
  };