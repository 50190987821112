import { MaterialSymbol } from "react-material-symbols";
import { ClaimAddEvidence, ClaimEvidence } from "../../../Models/Evidence";
import { useState } from "react";
import '../ClaimDetails.css'
import '../../../Components/Forms.css'

interface ClaimDetailsEvidenceProps {
    name: string;
    link: string;
    obtaining?: string;
    action: () => void;
}
export const ClaimDetailsEvidence: React.FC<ClaimDetailsEvidenceProps> = ({name, link, obtaining, action}) => {
    return (
        <div className="claim-details-evidence">
            <div className="evidence-info">
                <a href={link} target='_blank'>{name}</a>
            </div>
            { obtaining ? 
                <div className="evidence-detail">
                    <a href={obtaining} target='_blank'>(( Получение ))</a>
                </div>
            : ''}
            <div className="evidence-action hover-effect" onClick={action}>
                <MaterialSymbol icon="contract_edit" />
            </div>
        </div>
    )
}

interface ClaimEvidenceFormProps {
    currentEvidence?: ClaimEvidence;
    submit: (evidence: ClaimAddEvidence) => void;
    close: () => void;
};
  
export const ClaimEvidenceForm: React.FC<ClaimEvidenceFormProps> = ({ currentEvidence, submit, close }) => {
    const [formState, setFormState] = useState(currentEvidence ?? {link: '', name: '', obtaining: ''});
  
    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        setFormState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
  
    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        submit(formState);
        close();
    };
  
    return (
        <form className="form-container" onSubmit={handleFormSubmit}>
            <label htmlFor="name">Название Улики</label>
            <input id="name" name="name" value={formState.name} onChange={handleChange} required />
  
            <label htmlFor="link">Ссылка на Улику</label>
            <input id="link" name="link" value={formState.link} onChange={handleChange} required />
  
            <label htmlFor="obtaining">Ссылка на отыгровку (не обязательно):</label>
            <input id="obtaining" name="obtaining" value={formState.obtaining} onChange={handleChange} />
  
            <button type="submit">Подтвердить</button>
        </form>
    );
};