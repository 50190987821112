import React from 'react';
import { LawsuitFormStepProps } from './LawsuitForm';
import { IconButton } from '../../../Components/Buttons';
import { MaterialSymbol } from 'react-material-symbols';
import { red } from '../../../Services/Colors';

export const LawsuitFormStep2: React.FC<LawsuitFormStepProps> = ({ data, updateData }) => {
    return (
        <>
            <label htmlFor="description">Доказательства</label>
            <div id='evidences' className='buttoned-data'>
              {data.evidences?.map((evidence, index) => (
                <div key={evidence.id} className="evidence buttoned-item">
                  <div>({evidence.id.toString()}) {evidence.name}</div>
                  <IconButton action={() => {
                    const newEvidences = [...data.evidences];
                    newEvidences.splice(index, 1);
                    updateData({evidences: newEvidences});
                  }}
                    color={red}
                    small={true}
                  >
                    <MaterialSymbol icon="remove" color="white" size={22} />
                  </IconButton>
                </div>
              ))}
            </div>
        </>
    );
};