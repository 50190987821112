import { ReactNode } from "react"
import ReceiptsStatistic1 from "./Statistics/ReceiptsStatistics1"
import ReceiptsStatistic2 from "./Statistics/ReceiptsStatistics2"
import ReceiptsStatistic3 from "./Statistics/ReceiptsStatistics3"
import ReceiptsStatistic4 from "./Statistics/ReceiptsStatistics4"
import { MaterialSymbol } from "react-material-symbols"

const ReceiptsStatistics: React.FC = () => {
    return (<section className="statistic" style={{justifyContent:'center'}}>
        
        <div style={{ display: 'flex' }}>
            <MaterialSymbol icon="error" color="red" style={{ marginTop: 2.5, marginRight: 5 }} />
            <span>К сожалению статистика пока недоступна.</span>
        </div>
        {/* <ReceiptsStatisticWidget title="Новые чеки">
            <ReceiptsStatistic1 />
        </ReceiptsStatisticWidget>
        <ReceiptsStatisticWidget title="Адвокаты">
            <ReceiptsStatistic2 />
        </ReceiptsStatisticWidget>
        <ReceiptsStatisticWidget title="Не выплаченные">
            <ReceiptsStatistic3 />
        </ReceiptsStatisticWidget>
        <ReceiptsStatisticWidget title="Не оплаченные">
            <ReceiptsStatistic4 />
        </ReceiptsStatisticWidget> */}
    </section>)
}

interface ReceiptsStatisticWidgetProps {
    title: string
    children?: ReactNode
}
const ReceiptsStatisticWidget: React.FC<ReceiptsStatisticWidgetProps> = ({title, children}) => {
    return (<section className="widget">
        <h1>{title}</h1>
        <hr></hr>
        <div>
            {children}
        </div>
    </section>)
}
export default ReceiptsStatistics