import config from '../Config';
import { AdvocateShort, ClaimAdvocate } from '../Models/Advocate';
import { Claim, ClaimNew, ClaimShort } from '../Models/Claim';
import { ClaimClient, ClientShort } from '../Models/Client';
import { ClaimAddEvidence, ClaimEvidence } from '../Models/Evidence';

function validate(response: Response) {
    if (!response.ok) {

        console.error(response.statusText)

        if (response.status === 403 || response.status === 401) {
            throw new Error('Unauthorized');
        }
        if (response.status === 406 ){
            throw new Error('No Rights')
        }
        if (response.status === 404 ){
            throw new Error('Not Found')
        }
        throw new Error(`Error: ${response.statusText}`);
    }
}

export const getAPIClaim = async (id: bigint): Promise<Claim> => {
    const response = await fetch(`${config.api_server}/api/claim/${id}`, {
        credentials: 'include',
        method: 'GET',
    });

    validate(response)

    return response.json();
};
export const addAPIClaim = async(claim: ClaimNew): Promise<bigint> => {
        
    const response = await fetch(`${config.api_server}/api/claim`, {
        credentials: 'include',
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            description: claim.description ?? "",
            type: '-',
            number: 0,
            status: 0,
            happened:  claim.happened.getTime()
        })
    });

    validate(response)

    return response.json();
}
export const editAPIClaim = async (claim_id: bigint, updated: Partial<Claim>) => {

    const response = await fetch(`${config.api_server}/api/claim/${claim_id}`, {
        credentials: 'include',
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            ...updated,
            sent: updated.sent instanceof Date ? updated.sent.getTime() : updated.sent,
            hearing: updated.hearing instanceof Date ? updated.hearing.getTime() : updated.hearing,
            happened: updated.happened instanceof Date ? updated.happened.getTime() : updated.happened,
        })
    });

    validate(response);
    
    return response.json();
};

export const editAPIClient = async(client_id: number, updated: Partial<ClaimClient>) => {
    const response = await fetch(`${config.api_server}/api/client/${client_id}`, {
        credentials: 'include',
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(updated)
    });

    validate(response)

    return response.json();
}
export const editAPIAdvocate = async(advocate_id: number, updated: Partial<ClaimAdvocate>) => {
    const response = await fetch(`${config.api_server}/api/advocate/${advocate_id}`, {
        credentials: 'include',
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(updated)
    });

    validate(response)

    return response.json();
}
export const editAPIEvidence = async(evidence_id: bigint, updated: Partial<ClaimEvidence>) => {
    const response = await fetch(`${config.api_server}/api/evidence/${evidence_id}`, {
        credentials: 'include',
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(updated)
    });

    validate(response)

    return response.json();
}

export const claimAPIClient = async(claim_id: bigint, client_id: number, remove?: boolean) => {
    const response = await fetch(`${config.api_server}/api/claim/${claim_id}/client/${client_id}${remove ? '/remove' : ''}`, {
        credentials: 'include',
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
    });

    validate(response)

    return response.json();
}
export const claimAPIAdvocate = async(claim_id: bigint, advocate_id: number, remove?: boolean) => {
    const response = await fetch(`${config.api_server}/api/claim/${claim_id}/advocate/${advocate_id}${remove ? '/remove' : ''}`, {
        credentials: 'include',
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
    });

    validate(response)

    return response.json();
}
export const addAPIEvidence = async(claim_id: bigint, evidence: ClaimAddEvidence) => {
    
    const response = await fetch(`${config.api_server}/api/claim/${claim_id}/evidence/`, {
        credentials: 'include',
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(evidence)
    });

    validate(response)

    return response.json();
}

export const getAPIAdvocates = async(): Promise<AdvocateShort[]> => {
    const response = await fetch(`${config.api_server}/api/advocates/`, {
        credentials: 'include',
        method: 'GET',
    });

    validate(response)

    return response.json();
}
export const getAPIClients = async(): Promise<ClientShort[]> => {
    const response = await fetch(`${config.api_server}/api/clients/`, {
        credentials: 'include',
        method: 'GET',
    });

    validate(response)

    return response.json();
}