import { ReactNode } from "react"
import { getReceiptAmountLabel, getReceiptIssuedLabel, getReceiptReceivedAmount, getReceiptStatusColor, getReceiptStatusIcon, getReceiptStatusLabel, Receipt } from "../../Domains/Receipts"
import { MaterialSymbol } from "react-material-symbols"
import { blue, green, grey, orange, red } from "../../Services/Colors"

interface ReceiptsTableProps {
    receipts: Receipt[]
    onError: (receipt: Receipt) => void
    onComment: (receipt: Receipt) => void
    onStatus: (receipt: Receipt) => void
    onReturn: (receipt: Receipt) => void
}

const ReceiptsTable: React.FC<ReceiptsTableProps> = ({receipts, onError, onComment, onStatus, onReturn}) => {
    return (
    <div className="receipts-table-container">
        <table className="receipts-table">
            <thead>
                <tr>
                    <th>#</th>
                    <th className="align-right">сумма</th>
                    <th className="align-center">адвокат</th>
                    <th className="align-center">клиент</th>
                    <th className="align-center">выписан</th>
                    <th className="align-right">поступило</th>
                    <th className="align-center">статус</th>
                    <th> </th>
                    <th> </th>
                    <th> </th>
                    <th> </th>
                    <th> </th>
                </tr>
            </thead>
            <tbody>
                { receipts.map((receipt) => {
                    return <ReceiptsTableRow key={receipt.id} receipt={receipt} onError={onError} onComment={onComment} onStatus={onStatus} onReturn={onReturn}/>
                })}
            </tbody>
        </table>
    </div>)
}

interface ReceiptsTableRowProps {
    receipt: Receipt
    onError: (receipt: Receipt) => void
    onComment: (receipt: Receipt) => void
    onStatus: (receipt: Receipt) => void
    onReturn: (receipt: Receipt) => void
}

const ReceiptsTableRow: React.FC<ReceiptsTableRowProps> = ({receipt, onError, onComment, onStatus, onReturn}) => {
    return (<tr>
        <td className="align-right">{receipt.id}</td>
        <td className="align-right">{getReceiptAmountLabel(receipt.amount)}</td>
        <td className="align-center">{receipt.author.name}</td>
        <td className="align-center">{receipt.client.name}</td>
        <td className="align-center">{getReceiptIssuedLabel(new Date(`${receipt.issued}Z`))}</td>
        <td className="align-right">{getReceiptAmountLabel(getReceiptReceivedAmount(receipt))}</td> {/* */}
        <td style={{ color: getReceiptStatusColor(receipt.comment?.includes('Соглашение') ? 200 + receipt.status : 0 + receipt.status)}}  className="align-center">{getReceiptStatusLabel(receipt)}</td> {/*  */}
        <td><MaterialSymbol icon={getReceiptStatusIcon(receipt.status)} size={18} color={grey}/></td>
        {
            receipt.status >= 0 && receipt.status < 2 && receipt.comment?.includes('Соглашение')?
            <>
                <td><MaterialSymbol icon="arrow_right_alt" size={18} color={grey}/></td>
                <td onClick={() => onStatus(receipt)}><MaterialSymbol icon={getReceiptStatusIcon(receipt.status + 1)} size={18} color={green} className="actionable"/></td>
            </>
            : receipt.status >= 0 && receipt.status <= 2  && !receipt.comment?.includes('Соглашение')?
            <>
            <td><MaterialSymbol icon="arrow_right_alt" size={18} color={grey}/></td>
            <td onClick={() => onStatus(receipt)}><MaterialSymbol icon={getReceiptStatusIcon(receipt.status + 1)} size={18} color={green} className="actionable"/></td>
            </>
            :
            <>
                <td></td>
                <td></td>
            </>
        }
        {
            receipt.status >= 1?
            <td onClick={() => onReturn(receipt)}><MaterialSymbol icon="undo" size={18} color={orange} className="actionable"/></td>
            :
            <td></td>
        }
        <td onClick={() => onComment(receipt)} ><MaterialSymbol icon="comment" size={18} color={receipt.comment ? blue : grey} className="actionable"/></td>
        {
            receipt.status >= 0?
            <td onClick={() => onError(receipt)}><MaterialSymbol icon="report" size={18} color={red} className="actionable"/></td>
            :
            <td onClick={() => onReturn(receipt)}><MaterialSymbol icon="undo" size={18} color={red} className="actionable"/></td>

        }
    </tr>)
}
export default ReceiptsTable