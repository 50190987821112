import { get, post } from "./Server";
export interface Profile {
    name: string;
    passport: number;
    phone?: string;
    licenselink?: string;
    passlink?: string;
    discordAvatar?: string;
    discordName?: string;
    resigned?: string;
    role?: number;
    signature?: string;
    signaturelink?: string;
    joined?: string;
};

export async function getAPIProfile(id: number): Promise<Profile> {
    return await get(`/advocate/${id}`);
};

export async function setAPIProfile(id: number, updated: Partial<Profile>) {
    return await post(`/api/profile/${id}`, updated)
};
