import React, { useState } from 'react';
import { LawsuitFormStep1 } from './LawsuitFormStep1';
import { LawsuitFormStep2 } from './LawsuitFormStep2';
import { LawsuitFormStep3 } from './LawsuitFormStep3';
import { LawsuitFormStep4 } from './LawsuitFormStep4';
import { LawsuitFormStep5 } from './LawsuitFormStep5';
import { LawsuitFormStep6 } from './LawsuitFormStep6';
import { Claim } from '../../../Models/Claim';
import { IconButton } from '../../../Components/Buttons';
import { MaterialSymbol } from 'react-material-symbols';
import { green, red } from '../../../Services/Colors';
import './LawsuitForm.css';
import { getUpdatedFields, Lawsuit } from '../../../Services/Lawsuit';
import { editAPIClaim } from '../../../Services/Claim';

export interface LawsuitFormStepProps {
    data: Lawsuit;
    updateData: (updatedData: Partial<Lawsuit>) => void;
}
interface LawsuitFormProps {
    claim: Claim;
    close: () => void;
}
const LawsuitForm: React.FC<LawsuitFormProps> = ({ claim, close }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [data, setData] = useState<Lawsuit>({claim: claim, number: claim.number, type: claim.type, defendants: [], evidences: claim.evidences, demands: [], warnings: []})

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const updateData = (updatedData: Partial<Lawsuit>) => {
        setData((prevData) => ({
            ...prevData,
            ...updatedData
        }));
    };

    const handleConfirm = async () => {
        const fields = getUpdatedFields(claim, data);
    
        if (fields.length > 0) {
            const partialClaim: Partial<Claim> = {};
            
            fields.forEach(field => {
                partialClaim[field.field as keyof Claim] = field.newValue;
            });
    
            // Send the updated fields to the API
            await editAPIClaim(claim.id, partialClaim);
        }
    
        handleClose();
    };

    const handleClose = () => {
        close();
    };

    return (
        <div>
            <div className='lawsuit-form'>
                {activeStep === 0 && <LawsuitFormStep1 data={data} updateData={updateData} />}
                {activeStep === 1 && <LawsuitFormStep2 data={data} updateData={updateData} />}
                {activeStep === 2 && <LawsuitFormStep3 data={data} updateData={updateData} />}
                {activeStep === 3 && <LawsuitFormStep4 data={data} updateData={updateData} />}
                {activeStep === 4 && <LawsuitFormStep5 data={data} updateData={updateData} />}
                {activeStep === 5 && <LawsuitFormStep6 data={data} updateData={updateData} original={claim} />}
            </div>
            <LawsuitFormFooter step={activeStep} stepPlus={handleNext} stepMinus={handleBack} confirm={handleConfirm} disgard={handleClose}/>
        </div>
    );
};

interface LawsuitFormFooterProps {
    step: number;
    stepPlus: () => void;
    stepMinus: () => void;
    confirm: () => void;
    disgard: () => void;
}

const LawsuitFormFooter: React.FC<LawsuitFormFooterProps> = ({ step, stepPlus, stepMinus, confirm, disgard }) => { 
    switch (step) {
        case 0:
            return (
                <div className='lawsuit-footer' style={{ justifyContent: 'end' }}>
                    <IconButton action={stepPlus}>
                        <MaterialSymbol icon="chevron_right" color="white" size={20} />
                    </IconButton>
                </div>
            )
        case 5:
            return (
                <div className='lawsuit-footer' style={{  justifyContent: 'space-between' }}>
                    <IconButton action={stepMinus}>
                        <MaterialSymbol icon="chevron_left" color="white" size={20} />
                    </IconButton>
                    <div style={{ paddingLeft: '5px', display: "flex", gap: "5px"}}>
                        <IconButton action={disgard} color={red} label='Отменить' />
                        <IconButton action={confirm} color={green} label='Подтвердить Изменения'>
                            <MaterialSymbol icon="gavel" color="white" size={20} />
                        </IconButton>
                    </div>
                </div>
            )
        default:
            return (
                <div className='lawsuit-footer' style={{ justifyContent: 'space-between' }}>
                    <IconButton action={stepMinus}>
                        <MaterialSymbol icon="chevron_left" color="white" size={20} />
                    </IconButton>
                    <IconButton action={stepPlus}>
                        <MaterialSymbol icon="chevron_right" color="white" size={20} />
                    </IconButton>
                </div>
            )
    }
}

export default LawsuitForm;