import { useEffect, useState } from "react";
import { ClaimAdvocate } from "../../../Models/Advocate";
import { Claim } from "../../../Models/Claim";
import { ClaimEvidence } from "../../../Models/Evidence";
import { Warning } from "../../../Services/Lawsuit";
import { IconButton } from "../../../Components/Buttons";
import { MaterialSymbol } from "react-material-symbols";
import { green, red } from "../../../Services/Colors";
import { generateMotion } from "../../../Services/Motion";
import { getCurrentUserFromClaim } from "../../../Services/Login";
import { ClaimClient } from "../../../Models/Client";

interface MotionFormProps {
    claim: Claim;
    close: () => void;
}

export interface Motion {
    claim: Claim;
    option?: string;
    advocate?: ClaimAdvocate;
    client?: ClaimClient;
    evidences: ClaimEvidence[];
    warnings: Warning[];
    content?: string;
}

const options = ['Привлечение', 'Приобщение', 'Изменение', 'Перенос']

export const MotionForm: React.FC<MotionFormProps> = ({ claim, close }) => {
    const [data, setData] = useState<Motion>({claim: claim, warnings: [], evidences: claim.evidences})
    const [option, setOption] = useState<string>()

    useEffect(() => {
        const advocate = getCurrentUserFromClaim(claim)
        if (advocate) {
            updateData({advocate: advocate})
        }
    }, [])

    const updateData = (updatedData: Partial<Motion>) => {
        setData((prevData) => ({
            ...prevData,
            ...updatedData
        }));
    };

    const createMotion = () => {
        
        setOption(data.option)
        generateMotion(data)
        updateData({ content: generateMotion(data) })
    };

    return (
        <div>
            <div className='lawsuit-form'>
                { option == undefined ? 
                    <>
                    <label htmlFor="option">Выбор Ходатайства</label>
                    <select id="option" name="option" value={data.option} onChange={(event) => updateData({ option: event.target.value })} required>
                        <option value={undefined}>Не выбрано</option>
                        {options.map((option_, index) => (
                            <option key={index} value={option_}>{option_}</option>
                        ))}
                        <option value={''}>Другое</option>
                    </select>
                    <IconButton label='Сгенерировать' action={createMotion} color={green}>
                        <MaterialSymbol icon="gavel" color="white" size={22} />
                    </IconButton>
                    </>
                :  
                <>  
                    <textarea disabled>
                        {data.content}
                    </textarea>
                    <IconButton label='Скопировать' action={() => navigator.clipboard.writeText(data.content ?? '')} >
                        <MaterialSymbol icon="content_copy" color="white" size={22} />
                    </IconButton>
                </>
                }
                { data.option == "Приобщение" && <>
                    <label htmlFor="description">Доказательства</label>
                    <div id='evidences' className='buttoned-data'>
                    {data.evidences?.map((evidence, index) => (
                        <div key={evidence.id} className="evidence buttoned-item">
                        <div>({evidence.id.toString()}) {evidence.name}</div>
                        <IconButton action={() => {
                            const newEvidences = [...data.evidences];
                            newEvidences.splice(index, 1);
                            updateData({evidences: newEvidences});
                        }}
                            color={red}
                            small={true}
                        >
                            <MaterialSymbol icon="remove" color="white" size={22} />
                        </IconButton>
                        </div>
                    ))}
                    </div>
                </>  }
                { data.option == "Привлечение" && <>
                    <label htmlFor="client">Клиент</label>
                    <select id="client" name="client" value={data.client?.passport} onChange={(event) => updateData({ client: data.claim.clients.find(client => client.passport === parseInt(event.target.value)) })} required>
                        <option value={undefined}>Не выбрано</option>
                        {data.claim.clients.map((client) => (
                        <option key={client.passport} value={client.passport}>{client.name} ({client.passport})</option>
                        ))}
                    </select>
                </> }  
            </div>
        </div>
    );
}