import { MaterialSymbol } from "react-material-symbols";

interface ClaimDetailsPersonProps {
    passport: number;
    name: string;
    phone: string;
    discord: string;
    action: () => void;
}
export const ClaimDetailsPerson: React.FC<ClaimDetailsPersonProps> = ({passport, name, phone, discord, action}) => {
    return (
        <div className="claim-details-person">
            <div className="person-info">
                <div className="person-name">
                    {name} ({passport})
                </div>
                <div className="person-action" onClick={action}>
                    <MaterialSymbol icon="manage_accounts" />
                </div>
            </div>
            <div className="person-details">
                <div className="person-phone">{phone}</div>
                <div className="person-discord">{discord}</div>
            </div>
        </div>
    )
}