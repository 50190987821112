import productionConfig from './config.production.json';
import developmentConfig from './config.development.json';

interface Config {
    "oauth2_url": string;
    "api_server": string;
    "404_gif": string;
    "403_gif": string;
}

const config: Config = window.location.hostname.startsWith('mbalg.com') ? productionConfig : developmentConfig;

export default config;