import './Claims.css';
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { ClaimShort, getAPIclaims, getFilteredClaims } from "../../Domains/Claims";
import { getStatusLabel } from "../../Services/Convert";
import SearchHeader from "../../Components/Headers";
import Loader from "../../Components/Loader";
import { PopUp } from '../../Components/PupUp';
import CreateClaimForm from '../Claim/ClaimForms/CreateClaimForm';
import { isBookmarked } from '../../Services/Claims';

interface ClaimsProps {
  openClaim: (id: number) => void;
}

export const Claims: React.FC<ClaimsProps> = ({ openClaim }) => {
  const navigate = useNavigate();

  const [claims, setClaims] = useState<ClaimShort[]>([]);
  const [filteredClaims, setFilteredClaims] = useState<ClaimShort[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const [stared, setStared] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const fetchedClaims = await getAPIclaims();
        setClaims(fetchedClaims);
        setFilteredClaims(fetchedClaims);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    let filtered_claims = getFilteredClaims(query, claims)

    if (stared) {
      filtered_claims = filtered_claims.filter((claim) => isBookmarked(BigInt(claim.id)))
    }

    setFilteredClaims(filtered_claims);
  }, [query, stared]);

  return (
    <> 
      <h2>Дела</h2>
      <SearchHeader 
        icon_main="add"
        color_main="green"
        action_main={() => setIsCreating(true)}
        search={(event: React.ChangeEvent<HTMLInputElement>) => setQuery(event.target.value)}
        search_placeholder="Поиск (#id @клиент !статус *инстанция -номер ^дата)"
        icon_right="star"
        color_right={stared ? "orange" : "black"}
        action_right={() => setStared(!stared)}
      />
      <div className='claims-list'>
        {loading ? (
          <Loader />
        ) : (
          filteredClaims.length > 0 ? (
            filteredClaims.map((claim) => <Claim key={claim.id} info={claim} openClaim={() => { openClaim(claim.id)}} />)
          ) : (
            <p>Ничего не найдено.</p>
          )
        )}
      </div>
      <PopUp show={isCreating} title='Создание Дела' close={() => setIsCreating(false)}>
        <CreateClaimForm handleClose={() => {setIsCreating(false); navigate(`/?page=3`); window.location.reload();}} />
      </PopUp>
    </>
  );
};

interface ClaimProps {
  info: ClaimShort;
  openClaim: () => void
}
const Claim: React.FC<ClaimProps> = ({ info, openClaim }) => {
  return (
    <div className="claim-card" onClick={openClaim}>
      <div className="claim-card-header">
        <div>{`(${info.id})${info.type}-${info.number}`}</div>
        <div>{`${info.side === 0 ? 'Защита' : 'Обвинение'}`}</div>
      </div>
      <div className="claim-card-description">{info.description.split('\n')[0] ?? 'Без описания.'}</div>
      <div className="claim-card-footer">
        <div>{info.client.name}</div>
        <div>{`${getStatusLabel(info.status)}`}</div>
      </div>
    </div>
  );
};
