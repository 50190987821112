import { MaterialSymbolProps } from "react-material-symbols";
import { Person } from "./Person";
import { get } from "./Server";
import { Color } from "react-bootstrap/esm/types";
import { blue, green, grey, red } from "../Services/Colors";

export interface Hearing {
    id: bigint;
    label: string;
    participants: Person[];
    datetime: string;
}

const hearingIcons: { [key: string]: {icon: MaterialSymbolProps['icon'], color: Color} } = {
    '-1': {icon: 'history', color: grey},
    '0': {icon: 'notification_important', color: green},
    '1': {icon: 'update', color: blue},
};
export function getHearingDateIcon(hearing: Hearing): {icon: MaterialSymbolProps['icon'], color: Color} {
    const date = new Date(hearing.datetime);
    const today = new Date();
  
    if (date < today) {
      return hearingIcons['-1'];
    } else if (date > today) {
      return hearingIcons['1'];
    } else {
      return hearingIcons['0'];
    }
}
export async function getAPIhearings(): Promise<Hearing[]> {
    return await get('/api/hearings');
};