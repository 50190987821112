import './index.css';
import 'react-material-symbols/rounded';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Claims from './Core/Claim/Claims';
import Login from './Core/Login';
import Home from './Core/Home';
import NotFound from './Core/NotFound';
import Receipts from './Core/Receipts/Receipts';
import Discord from './Core/Discord';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/archive/claims/" element={<Claims />}/>
        <Route path="/archive/receipts/" element={<Receipts />} />
        <Route path='/discord/' element={<Discord />} />
        <Route path="/login/" element={<Login />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
